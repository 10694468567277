import React from "react";
import { useSelector } from "react-redux";
import { ThemeEnum, themes } from "../../../theme";
import { ObjectType } from "../../../types";

const ContactImg = () => {
	const themeState: ThemeEnum = useSelector(
		(state: ObjectType) => state.theme.value,
	);
	const theme = themes[themeState];
	return (
		<svg version='1.2' width='100%' height='100%' viewBox='300 300 3010 3010'>
			<g>
				<path
					fill={theme.secondaryText}
					d='M3115.698,2342.071c-267.081,628.868-978.523,981.165-1595.487,886.134
			c-100.392-15.464-375.621-61.526-619.298-278.836c-469.095-418.337-569.414-1246.699-191.01-1830.465
			c275.412-424.879,769.116-665.729,1238.232-653.681c101.047,2.595,488.304,18.588,828.635,299.218
			c49.988,41.219,160.241,139.167,259.606,294.554C3269.702,1423.874,3289.757,1932.23,3115.698,2342.071z'
				/>
				<g>
					<g>
						<g opacity='0.3'>
							<path
								fill='#91B3FA'
								d='M2177.647,875.63c-5.452-0.222-9.689-4.825-9.465-10.274
						c0.433-10.589,0.415-21.355-0.058-31.999c-0.239-5.385,3.882-9.953,9.238-10.293c0.063-0.004,0.127-0.008,0.192-0.012
						c5.449-0.242,10.062,3.981,10.304,9.432c0.497,11.203,0.518,22.536,0.06,33.684c-0.208,5.097-4.249,9.134-9.231,9.45
						C2178.345,875.639,2177.998,875.646,2177.647,875.63z M2162.271,781.709c-1.917-10.475-4.302-20.966-7.088-31.193
						c-1.434-5.259,1.668-10.691,6.931-12.127c5.287-1.422,10.693,1.672,12.127,6.935c2.933,10.758,5.443,21.802,7.463,32.823
						c0.982,5.365-2.573,10.513-7.938,11.495c-0.39,0.071-0.779,0.119-1.164,0.143
						C2167.675,790.099,2163.183,786.684,2162.271,781.709z M2138.067,701.465c-4.19-9.758-8.854-19.452-13.87-28.818
						c-2.574-4.808-0.762-10.795,4.045-13.37c4.807-2.567,10.792-0.762,13.37,4.046c5.281,9.863,10.195,20.074,14.605,30.348
						c2.152,5.013-0.168,10.82-5.179,12.974c-1.069,0.458-2.173,0.714-3.271,0.784
						C2143.718,707.686,2139.76,705.409,2138.067,701.465z M2096.524,628.651c-6.272-8.586-12.991-16.997-19.967-25.005
						c-3.582-4.114-3.153-10.354,0.959-13.936c4.116-3.578,10.352-3.147,13.936,0.959c7.345,8.433,14.417,17.29,21.022,26.323
						c3.217,4.404,2.257,10.584-2.147,13.802c-1.576,1.151-3.379,1.769-5.192,1.884
						C2101.878,632.884,2098.592,631.477,2096.524,628.651z M2039.794,566.906c-8.003-6.961-16.419-13.679-25.013-19.961
						c-4.403-3.223-5.361-9.402-2.143-13.805c3.221-4.407,9.402-5.364,13.803-2.14c9.041,6.609,17.896,13.678,26.317,21.003
						c4.116,3.578,4.551,9.817,0.969,13.933c-1.803,2.075-4.282,3.214-6.826,3.375
						C2044.399,569.471,2041.836,568.682,2039.794,566.906z M1970.817,519.253c-9.358-5.027-19.047-9.712-28.802-13.929
						c-5.007-2.164-7.312-7.979-5.148-12.984c2.164-5.009,7.975-7.314,12.985-5.15c10.265,4.436,20.465,9.371,30.314,14.66
						c4.805,2.583,6.608,8.569,4.027,13.376c-1.677,3.122-4.791,4.974-8.085,5.183
						C1974.33,520.522,1972.502,520.159,1970.817,519.253z M1604.068,514.654c-2.567-4.812-0.747-10.796,4.067-13.363
						c9.861-5.257,20.086-10.155,30.388-14.555c5.017-2.143,10.821,0.187,12.962,5.204c2.143,5.013-0.187,10.817-5.203,12.96
						c-9.782,4.178-19.49,8.83-28.853,13.823c-1.285,0.685-2.651,1.057-4.016,1.144
						C1609.668,520.105,1605.949,518.184,1604.068,514.654z M1893.04,488.093c-10.236-2.818-20.719-5.226-31.159-7.154
						c-5.365-0.993-8.91-6.146-7.919-11.509c0.991-5.367,6.142-8.883,11.509-7.917c10.994,2.032,22.034,4.567,32.813,7.536
						c5.258,1.448,8.348,6.885,6.9,12.146c-1.148,4.171-4.807,6.978-8.896,7.237C1895.223,488.5,1894.128,488.391,1893.04,488.093z
						 M1683.266,480.919c-1.424-5.264,1.691-10.689,6.957-12.113c10.786-2.915,21.842-5.403,32.856-7.401
						c5.368-0.998,10.506,2.592,11.481,7.958c0.972,5.366-2.59,10.506-7.958,11.481c-10.468,1.898-20.975,4.262-31.225,7.034
						c-0.654,0.177-1.31,0.28-1.961,0.322C1688.827,488.491,1684.511,485.529,1683.266,480.919z M1810.265,475.1
						c-10.602-0.456-21.363-0.461-31.987-0.011c-5.451,0.234-10.056-3.997-10.288-9.449c-0.227-5.379,3.891-9.935,9.239-10.275
						c0.069-0.004,0.141-0.009,0.21-0.009c11.185-0.478,22.514-0.474,33.673,0.004c5.45,0.238,9.679,4.844,9.445,10.295
						c-0.218,5.09-4.256,9.115-9.23,9.431C1810.977,475.109,1810.622,475.112,1810.265,475.1z'
							/>
							<path
								fill='#91B3FA'
								d='M1555.42,557.905c1.941-0.123,3.866-0.817,5.506-2.12c3.988-3.168,8.109-6.302,12.247-9.325
						c4.406-3.214,5.373-9.395,2.157-13.799c-3.214-4.408-9.397-5.38-13.801-2.155c-4.354,3.176-8.687,6.474-12.885,9.806
						c-4.271,3.391-4.986,9.606-1.596,13.876C1549.139,556.823,1552.301,558.103,1555.42,557.905z'
							/>
						</g>
						<g opacity='0.3'>
							<path
								fill='#91B3FA'
								d='M1411.427,822.374c4.757-0.302,8.726-4.018,9.196-8.915c0.484-5.068,1.084-10.213,1.782-15.287
						c0.743-5.405-3.035-10.389-8.438-11.132c-5.427-0.746-10.388,3.037-11.132,8.439c-0.734,5.342-1.366,10.76-1.878,16.097
						c-0.519,5.43,3.461,10.253,8.891,10.774C1410.381,822.401,1410.909,822.407,1411.427,822.374z'
							/>
							<path
								fill='#91B3FA'
								d='M1798.878,1244.033c-5.454,0.079-9.94-4.279-10.018-9.737
						c-0.077-5.454,4.276-9.928,9.735-10.016c10.384-0.149,20.923-0.744,31.445-1.768c5.401-0.544,10.259,3.447,10.787,8.876
						c0.528,5.429-3.445,10.258-8.874,10.789c-4.559,0.444-9.107,0.81-13.643,1.098
						C1811.839,1243.685,1805.301,1243.942,1798.878,1244.033z M1873.162,1226.007c-1.257-5.305,2.026-10.628,7.333-11.885
						c10.19-2.417,20.388-5.288,30.31-8.538c5.172-1.712,10.763,1.126,12.462,6.31c1.697,5.181-1.129,10.761-6.311,12.458
						c-10.447,3.424-21.182,6.448-31.905,8.988c-0.556,0.132-1.114,0.214-1.664,0.249
						C1878.672,1233.888,1874.289,1230.76,1873.162,1226.007z M1745.134,1241.119c-10.906-1.345-21.905-3.175-32.69-5.444
						c-5.338-1.123-8.756-6.36-7.634-11.701c1.123-5.337,6.359-8.747,11.699-7.632c10.242,2.157,20.684,3.895,31.041,5.17
						c5.414,0.669,9.262,5.598,8.595,11.014c-0.591,4.789-4.519,8.351-9.166,8.646
						C1746.375,1241.21,1745.758,1241.196,1745.134,1241.119z M1953.642,1199.574c-2.393-4.901-0.36-10.817,4.543-13.208
						c9.397-4.587,18.709-9.631,27.678-14.99c4.685-2.802,10.745-1.274,13.544,3.412c2.798,4.682,1.271,10.744-3.41,13.542
						c-9.446,5.645-19.253,10.957-29.147,15.787c-1.196,0.582-2.451,0.906-3.703,0.985
						C1959.273,1205.348,1955.451,1203.279,1953.642,1199.574z M1660.698,1221.014c-10.337-3.709-20.659-7.913-30.683-12.496
						c-4.961-2.267-7.144-8.125-4.878-13.086c2.267-4.965,8.129-7.146,13.089-4.879c9.522,4.352,19.326,8.345,29.143,11.866
						c5.135,1.843,7.804,7.5,5.961,12.634c-1.373,3.826-4.863,6.282-8.675,6.524
						C1663.351,1221.661,1662.006,1221.483,1660.698,1221.014z M1582.718,1182.878c-9.299-5.893-18.456-12.247-27.22-18.885
						c-4.348-3.296-5.203-9.489-1.909-13.839c3.3-4.354,9.493-5.207,13.837-1.907c8.327,6.307,17.03,12.345,25.866,17.947
						c4.608,2.92,5.975,9.023,3.055,13.631c-1.761,2.775-4.677,4.375-7.724,4.569
						C1586.613,1184.521,1584.547,1184.037,1582.718,1182.878z M1514.899,1128.652c-7.799-7.782-15.355-15.981-22.459-24.364
						c-3.527-4.164-3.01-10.394,1.15-13.921c4.167-3.531,10.396-3.01,13.921,1.15c6.749,7.968,13.93,15.755,21.342,23.151
						c3.861,3.853,3.868,10.108,0.015,13.967c-1.77,1.775-4.044,2.734-6.362,2.882
						C1519.775,1131.69,1516.987,1130.735,1514.899,1128.652z M1460.525,1060.924c-5.908-9.298-11.487-18.956-16.58-28.708
						c-2.526-4.834-0.655-10.802,4.182-13.328c4.827-2.522,10.8-0.651,13.327,4.184c4.836,9.257,10.133,18.427,15.746,27.26
						c2.925,4.604,1.565,10.706-3.039,13.631c-1.455,0.924-3.057,1.42-4.663,1.521
						C1466.018,1065.706,1462.528,1064.075,1460.525,1060.924z M1422.342,982.863c-3.705-10.346-7.015-20.988-9.84-31.633
						c-1.4-5.273,1.74-10.684,7.013-12.082c5.256-1.393,10.68,1.739,12.08,7.012c2.683,10.113,5.828,20.22,9.345,30.048
						c1.839,5.133-0.834,10.789-5.969,12.626c-0.894,0.32-1.804,0.505-2.707,0.563
						C1427.982,989.669,1423.86,987.104,1422.342,982.863z M1402.252,898.411c-1.005-8.035-1.774-16.202-2.286-24.268
						c-0.186-2.922-0.338-5.835-0.459-8.747c-0.226-5.448,3.935-10.154,9.457-10.276c5.45-0.23,10.052,4.005,10.281,9.457
						c0.116,2.765,0.259,5.539,0.435,8.315c0.487,7.67,1.217,15.429,2.172,23.07c0.678,5.412-3.163,10.348-8.575,11.025
						c-0.203,0.024-0.407,0.045-0.609,0.058C1407.499,907.372,1402.902,903.62,1402.252,898.411z'
							/>
							<path
								fill='#91B3FA'
								d='M2034.858,1159.599c1.96-0.124,3.9-0.831,5.547-2.15c4.172-3.346,8.351-6.843,12.422-10.395
						c4.112-3.586,4.538-9.825,0.952-13.935c-3.587-4.11-9.822-4.542-13.935-0.952c-3.868,3.373-7.839,6.695-11.798,9.872
						c-4.255,3.409-4.939,9.626-1.526,13.883C2028.613,1158.53,2031.759,1159.795,2034.858,1159.599z'
							/>
						</g>
						<g>
							<path
								fill='#91B3FA'
								d='M2053.516,991.302c1.146-1.603,1.15-3.754,0.009-5.36l-32.539-45.804
						c10-24.259,16.202-50.286,17.977-77.229l49.372-26.742c1.732-0.938,2.677-2.872,2.355-4.815l-11.026-66.484
						c-0.321-1.943-1.842-3.469-3.782-3.797l-55.362-9.373c-10.376-24.929-24.649-47.562-41.944-67.293l16.012-53.854
						c0.561-1.888-0.137-3.924-1.739-5.07l-54.808-39.216c-1.601-1.146-3.755-1.15-5.36-0.009l-45.803,32.539
						c-24.258-10-50.286-16.204-77.23-17.977l-26.742-49.372c-0.938-1.732-2.871-2.678-4.814-2.355l-66.486,11.025
						c-1.943,0.323-3.467,1.842-3.796,3.784l-9.373,55.362c-24.929,10.376-47.561,24.649-67.293,41.944l-53.855-16.013
						c-1.887-0.561-3.924,0.136-5.07,1.739l-39.216,54.808c-1.146,1.601-1.149,3.754-0.009,5.359l32.54,45.804
						c-10.001,24.258-16.203,50.285-17.977,77.229l-49.372,26.742c-1.732,0.937-2.678,2.871-2.356,4.814l11.026,66.486
						c0.323,1.943,1.842,3.467,3.783,3.796l55.362,9.374c10.377,24.929,24.649,47.561,41.944,67.293l-16.012,53.854
						c-0.562,1.887,0.136,3.923,1.739,5.07l54.809,39.216c1.602,1.146,3.754,1.149,5.359,0.009l45.804-32.54
						c24.258,10,50.285,16.203,77.23,17.978l26.742,49.372c0.938,1.73,2.871,2.677,4.814,2.355l66.485-11.026
						c1.943-0.323,3.468-1.841,3.796-3.784l9.373-55.361c24.929-10.377,47.561-24.65,67.293-41.944l53.855,16.013
						c1.887,0.562,3.924-0.137,5.07-1.739L2053.516,991.302z'
							/>
							<g>
								<path
									fill='#BDD0FB'
									d='M1787.648,914.031c-37.224-3.096-64.991-35.894-61.897-73.123
							c1.497-18.033,9.93-34.403,23.741-46.093c13.807-11.69,31.344-17.307,49.38-15.803c37.225,3.096,64.991,35.895,61.898,73.123
							c-2.816,33.867-30.218,59.903-63.173,61.995C1794.329,914.337,1791.004,914.309,1787.648,914.031z M1759.702,806.878
							c-10.59,8.965-17.053,21.514-18.203,35.342c-2.371,28.538,18.918,53.691,47.458,56.063
							c28.548,2.383,53.691-18.918,56.064-47.46c2.371-28.538-18.918-53.692-47.458-56.063c-2.588-0.215-5.159-0.237-7.704-0.076
							C1778.807,795.386,1768.309,799.594,1759.702,806.878z'
								/>
							</g>
							<path
								fill='#BDD0FB'
								d='M1802.412,1011.864c5.66-0.359,11.335-1.005,17.006-1.945l0,0
						c1.785-0.295,3.566-0.621,5.341-0.973c8.752-1.742,17.352-4.178,25.666-7.269c0.113-0.042,0.227-0.084,0.34-0.126
						c0.321-0.121,0.642-0.242,0.963-0.367c0.625-0.237,1.247-0.481,1.868-0.725c1.633-0.645,2.596-2.256,2.492-3.92
						c-0.026-0.404-0.115-0.812-0.272-1.208c-0.802-2.029-3.099-3.016-5.126-2.219c-10.441,4.131-21.396,7.161-32.563,9.01
						c-55.466,9.208-109.089-11.698-144.134-50.715c-19.495-21.699-33.24-49.005-38.354-79.856
						c-0.898-5.41-1.516-10.825-1.857-16.224c-2.302-36.332,7.877-72.054,29.348-102.06c24.661-34.468,61.27-57.27,103.079-64.203
						c86.349-14.311,168.176,44.264,182.488,130.57c0.883,5.321,1.493,10.668,1.83,16.016c1.467,23.176-2.153,46.416-10.683,68.038
						c-0.22,0.559-0.305,1.137-0.268,1.699c0.094,1.479,1.021,2.846,2.493,3.425c2.033,0.795,4.326-0.189,5.126-2.227
						c8.955-22.701,12.758-47.101,11.218-71.432c-0.354-5.614-0.995-11.225-1.919-16.811
						c-15.026-90.615-100.995-152.097-191.576-137.077c-43.894,7.279-82.323,31.216-108.212,67.401
						c-22.542,31.5-33.226,69.001-30.811,107.144c0.36,5.668,1.009,11.352,1.949,17.029c7.28,43.896,31.216,82.327,67.4,108.216
						C1726.749,1003.604,1764.262,1014.286,1802.412,1011.864z'
							/>
						</g>
					</g>
					<g>
						<g opacity='0.3'>
							<path
								fill='#91B3FA'
								d='M2531.554,1114.031c2.634-0.167,4.951-2.039,5.561-4.746c1.142-5.051,2.146-10.207,2.982-15.322
						c0.544-3.324-1.708-6.458-5.035-7.005c-3.328-0.558-6.461,1.712-7.005,5.035c-0.798,4.874-1.754,9.788-2.842,14.609
						c-0.743,3.283,1.32,6.548,4.606,7.295C2530.405,1114.026,2530.986,1114.067,2531.554,1114.031z'
							/>
							<path
								fill='#91B3FA'
								d='M2537.021,1067.511c-3.366-0.132-5.99-2.963-5.86-6.332c0.323-8.497,0.215-17.109-0.323-25.594
						c-0.114-1.789-0.249-3.584-0.402-5.383c-0.281-3.354,2.208-6.307,5.566-6.591c3.185-0.142,6.307,2.209,6.594,5.569
						c0.158,1.882,0.299,3.76,0.417,5.633c0.564,8.894,0.678,17.925,0.336,26.835c-0.119,3.153-2.616,5.658-5.702,5.854
						C2537.442,1067.514,2537.233,1067.515,2537.021,1067.511z M2525.95,999.548c-2.112-10.091-4.866-20.142-8.191-29.87
						c-1.089-3.19,0.61-6.653,3.8-7.745c3.173-1.079,6.656,0.61,7.745,3.8c3.485,10.195,6.375,20.733,8.587,31.314
						c0.69,3.299-1.424,6.531-4.723,7.224c-0.289,0.06-0.582,0.096-0.87,0.115C2529.319,1004.575,2526.58,1002.553,2525.95,999.548z
						 M2505.931,941.041c-4.512-9.252-9.654-18.316-15.285-26.949c-1.838-2.82-1.043-6.601,1.777-8.44
						c2.832-1.845,6.601-1.052,8.443,1.775c5.905,9.05,11.297,18.563,16.031,28.262c1.476,3.028,0.221,6.684-2.807,8.159
						c-0.739,0.357-1.514,0.556-2.287,0.605C2509.41,944.605,2507.049,943.328,2505.931,941.041z M2472.148,889.24
						c-6.654-7.875-13.86-15.41-21.409-22.381c-2.476-2.29-2.629-6.149-0.341-8.622c2.282-2.467,6.143-2.628,8.619-0.344
						c7.92,7.317,15.471,15.213,22.451,23.473c2.175,2.572,1.849,6.425-0.723,8.6c-1.04,0.872-2.284,1.345-3.545,1.425
						C2475.337,891.509,2473.446,890.776,2472.148,889.24z M2426.742,847.257c-8.365-6.018-17.18-11.571-26.203-16.514
						c-2.953-1.615-4.036-5.325-2.418-8.281c1.618-2.956,5.325-4.045,8.281-2.418c9.456,5.178,18.696,11.003,27.465,17.305
						c2.733,1.97,3.357,5.78,1.391,8.514c-1.111,1.545-2.811,2.417-4.571,2.529C2429.33,848.477,2427.934,848.112,2426.742,847.257z
						 M2372.521,817.568c-9.568-3.798-19.461-7.032-29.412-9.612c-3.259-0.844-5.219-4.176-4.373-7.435
						c0.847-3.259,4.164-5.213,7.438-4.376c10.433,2.705,20.814,6.099,30.851,10.083c3.129,1.246,4.662,4.79,3.419,7.919
						c-0.901,2.266-3.007,3.695-5.287,3.84C2374.286,818.041,2373.388,817.913,2372.521,817.568z M2312.711,802.047
						c-10.176-1.33-20.571-2.037-30.899-2.098c-3.368-0.025-6.083-2.772-6.06-6.14c0.019-3.367,2.763-6.031,6.139-6.067
						c10.827,0.071,21.726,0.812,32.399,2.206c3.341,0.438,5.696,3.5,5.26,6.841c-0.383,2.938-2.803,5.115-5.657,5.296
						C2313.506,802.11,2313.112,802.1,2312.711,802.047z M2213.015,802.669c-0.799-3.274,1.206-6.571,4.481-7.37
						c9.061-2.211,18.399-3.961,27.729-5.205c1.417-0.197,2.83-0.37,4.246-0.538c3.318-0.485,6.377,2.007,6.76,5.361
						c0.388,3.348-2.01,6.371-5.358,6.763c-1.328,0.15-2.657,0.319-4.009,0.506c-8.93,1.187-17.837,2.857-26.479,4.964
						c-0.356,0.088-0.714,0.141-1.064,0.163C2216.422,807.497,2213.725,805.585,2213.015,802.669z'
							/>
						</g>
						<g opacity='0.3'>
							<path
								fill='#91B3FA'
								d='M2293.07,1314.416c-10.618,0.536-21.361,0.424-31.932-0.319
						c-3.358-0.24-5.892-3.154-5.655-6.518c0.237-3.364,3.177-5.861,6.518-5.655c10.08,0.715,20.327,0.817,30.457,0.305
						c3.365-0.172,6.23,2.422,6.399,5.784c0.167,3.338-2.38,6.186-5.704,6.397
						C2293.153,1314.411,2293.096,1314.415,2293.07,1314.416z M2317.783,1305.911c-0.571-3.318,1.658-6.475,4.977-7.044
						c9.988-1.72,19.972-4.061,29.676-6.963c3.211-0.95,6.628,0.869,7.591,4.103c0.966,3.228-0.869,6.621-4.097,7.59
						c-10.17,3.039-20.634,5.494-31.1,7.293c-0.221,0.038-0.437,0.064-0.657,0.078
						C2321.104,1311.162,2318.318,1309.017,2317.783,1305.911z M2229.456,1309.894c-10.439-2.048-20.842-4.748-30.918-8.031
						c-3.203-1.044-4.956-4.491-3.911-7.691c1.041-3.206,4.473-4.946,7.688-3.915c9.612,3.133,19.533,5.709,29.492,7.667
						c3.307,0.65,5.459,3.856,4.812,7.156c-0.546,2.781-2.898,4.745-5.598,4.916
						C2230.51,1310.03,2229.986,1309.998,2229.456,1309.894z M2377.9,1289.44c-1.354-3.084,0.053-6.683,3.137-8.037
						c9.281-4.07,18.401-8.749,27.11-13.916c2.897-1.712,6.641-0.756,8.358,2.131c1.718,2.9,0.764,6.643-2.135,8.361
						c-9.132,5.421-18.7,10.332-28.434,14.597c-0.674,0.293-1.371,0.457-2.063,0.501
						C2381.389,1293.236,2378.958,1291.85,2377.9,1289.44z M2168.843,1290.139c-9.618-4.488-19.062-9.613-28.074-15.231
						c-2.859-1.782-3.731-5.548-1.948-8.406c1.782-2.86,5.544-3.737,8.406-1.949c8.593,5.364,17.6,10.249,26.775,14.525
						c3.052,1.424,4.374,5.053,2.947,8.11c-0.974,2.092-2.991,3.371-5.146,3.508
						C2170.82,1290.759,2169.802,1290.585,2168.843,1290.139z M2114.73,1256.414c-8.28-6.674-16.228-13.912-23.622-21.507
						c-2.349-2.412-2.299-6.271,0.115-8.621c2.409-2.35,6.274-2.302,8.627,0.115c7.053,7.241,14.636,14.144,22.538,20.513
						c2.623,2.114,3.034,5.956,0.92,8.579c-1.118,1.385-2.717,2.155-4.367,2.259
						C2117.474,1257.845,2115.966,1257.409,2114.73,1256.414z M2070.246,1210.7c-6.447-8.467-12.428-17.405-17.779-26.557
						c-1.701-2.912-0.72-6.646,2.189-8.346c2.907-1.701,6.644-0.714,8.344,2.186c5.1,8.731,10.804,17.252,16.955,25.321
						c2.041,2.682,1.523,6.511-1.159,8.55c-0.993,0.761-2.144,1.162-3.303,1.236
						C2073.524,1213.214,2071.532,1212.385,2070.246,1210.7z'
							/>
							<path
								fill='#91B3FA'
								d='M2437.707,1261.122c1.174-0.074,2.339-0.489,3.34-1.263c4.131-3.193,8.185-6.525,12.054-9.898
						c2.537-2.215,2.802-6.07,0.588-8.61c-2.218-2.527-6.066-2.796-8.61-0.587c-3.688,3.219-7.557,6.395-11.498,9.445
						c-2.666,2.061-3.154,5.895-1.093,8.557C2433.777,1260.428,2435.753,1261.246,2437.707,1261.122z'
							/>
						</g>
						<g>
							<path
								fill='#91B3FA'
								d='M2453.895,1146.119c0.766-1.073,0.769-2.512,0.006-3.587l-21.774-30.65
						c6.691-16.233,10.841-33.649,12.029-51.678l33.037-17.895c1.159-0.628,1.791-1.921,1.576-3.222l-7.378-44.488
						c-0.215-1.301-1.232-2.321-2.531-2.54l-37.046-6.272c-6.944-16.681-16.494-31.826-28.067-45.03l10.714-36.037
						c0.376-1.263-0.092-2.626-1.164-3.393l-36.675-26.241c-1.071-0.767-2.512-0.77-3.587-0.006l-30.649,21.774
						c-16.233-6.692-33.65-10.843-51.68-12.03l-17.894-33.037c-0.628-1.159-1.921-1.792-3.221-1.576l-44.49,7.378
						c-1.301,0.216-2.32,1.233-2.54,2.532l-6.272,37.046c-16.681,6.943-31.826,16.494-45.029,28.067l-36.038-10.715
						c-1.263-0.375-2.626,0.091-3.392,1.163l-26.242,36.676c-0.766,1.071-0.768,2.512-0.006,3.585l21.774,30.651
						c-6.692,16.232-10.843,33.648-12.03,51.678l-33.038,17.895c-1.159,0.627-1.792,1.921-1.576,3.221l7.378,44.49
						c0.216,1.3,1.232,2.32,2.532,2.54l37.045,6.272c6.943,16.681,16.494,31.826,28.067,45.03l-10.715,36.037
						c-0.376,1.263,0.091,2.625,1.163,3.393l36.675,26.241c1.072,0.767,2.512,0.769,3.587,0.006l30.649-21.774
						c16.232,6.692,33.649,10.842,51.679,12.029l17.894,33.037c0.628,1.158,1.921,1.792,3.222,1.576l44.489-7.378
						c1.3-0.216,2.321-1.232,2.54-2.532l6.272-37.045c16.682-6.943,31.826-16.495,45.029-28.067l36.037,10.715
						c1.263,0.376,2.626-0.092,3.392-1.163L2453.895,1146.119z'
							/>
							<g>
								<path
									fill='#BDD0FB'
									d='M2275.924,1095.223c-12.287-1.017-23.438-6.762-31.401-16.172
							c-7.963-9.404-11.787-21.35-10.764-33.635c2.107-25.354,24.416-44.231,49.81-42.165c25.358,2.115,44.269,24.458,42.159,49.807
							c-1.02,12.284-6.762,23.435-16.169,31.399c-7.647,6.473-16.973,10.21-26.795,10.834
							C2280.507,1095.433,2278.219,1095.411,2275.924,1095.223z M2245.917,1046.423c-0.751,9.038,2.06,17.825,7.92,24.748
							c5.856,6.917,14.061,11.142,23.095,11.894l0,0c9.037,0.739,17.822-2.063,24.745-7.922c6.92-5.853,11.142-14.061,11.894-23.092
							c1.55-18.651-12.36-35.086-31.011-36.641c-1.684-0.138-3.347-0.152-4.985-0.048
							C2261.06,1016.409,2247.326,1029.458,2245.917,1046.423z'
								/>
							</g>
							<path
								fill='#BDD0FB'
								d='M2286.204,1163.314c3.853-0.245,7.722-0.688,11.61-1.328c7.718-1.278,15.314-3.342,22.595-6.144
						c0.326-0.128,0.653-0.257,0.979-0.385c2.522-0.996,4.008-3.484,3.848-6.052c-0.04-0.624-0.178-1.254-0.42-1.865
						c-1.238-3.133-4.784-4.651-7.915-3.426c-6.773,2.681-13.865,4.641-21.078,5.833l-0.003,0c-0.396,0.067-0.794,0.128-1.188,0.189
						c-17.097,2.667-34.211,0.972-50.083-4.706c-8.615-3.083-16.867-7.347-24.549-12.739c-0.063-0.044-0.125-0.088-0.187-0.131
						c-0.191-0.138-0.381-0.268-0.569-0.406c-22.312-15.967-37.074-39.665-41.562-66.732c-0.584-3.503-0.982-7.006-1.204-10.501
						c-1.49-23.52,5.101-46.646,19-66.075c15.967-22.312,39.666-37.069,66.73-41.556c27.05-4.475,54.261,1.833,76.577,17.793
						c22.312,15.966,37.074,39.665,41.562,66.732c0.574,3.443,0.967,6.905,1.186,10.365c0.949,15.007-1.394,30.049-6.916,44.045
						c-0.339,0.863-0.471,1.755-0.413,2.623c0.145,2.283,1.577,4.395,3.848,5.29c3.137,1.228,6.677-0.298,7.915-3.44
						c6.178-15.661,8.802-32.495,7.742-49.279c-0.246-3.876-0.685-7.746-1.324-11.598c-5.023-30.286-21.537-56.798-46.503-74.662
						c-24.963-17.858-55.374-24.954-85.668-19.909c-30.283,5.02-56.796,21.531-74.656,46.5
						c-15.553,21.731-22.924,47.604-21.257,73.919c0.248,3.906,0.694,7.83,1.343,11.746
						C2175.369,1126.044,2228.123,1167,2286.204,1163.314z'
							/>
						</g>
					</g>
				</g>
				<g>
					<path
						fill='#91B3FA'
						d='M3210.829,1745.575c-0.153-8.832-3.843-17.234-10.241-23.325
				c-6.398-6.09-14.971-9.36-23.802-9.078l-714.453,22.829c-18.184,0.581-32.467,15.774-31.926,33.96l35.252,1183.243
				c0.536,17.956,15.337,32.184,33.3,32.01l700.092-6.809c8.793-0.085,17.189-3.678,23.324-9.981
				c6.133-6.302,9.496-14.793,9.344-23.585L3210.829,1745.575z'
					/>
					<g opacity='0.43'>
						<path
							fill='#FFFFFF'
							d='M3136.294,1803.447h-636.224c-6.188,0-11.153,3.302-11.089,9.169l0.607,55.549
					c0.064,5.893,5.167,12.25,11.372,12.256l638.035,0.513c6.317,0.007,11.206-6.854,11.003-12.8l-1.935-55.377
					C3147.862,1806.837,3142.593,1803.447,3136.294,1803.447z'
						/>
						<path
							fill='#FFFFFF'
							d='M3140.425,1923.152l-638.993-1.063c-6.215-0.01-11.189,5.922-11.125,11.839l0.613,56.765
					c0.064,5.944,5.177,11.58,11.41,11.597l640.82,1.77c6.345,0.018,11.294-5.641,11.09-11.64l-1.953-57.282
					C3152.083,1929.166,3146.75,1923.162,3140.425,1923.152z'
						/>
						<path
							fill='#FFFFFF'
							d='M3144.736,2051.114l-641.884-2.183c-6.242-0.022-11.272,2.863-11.207,8.832l0.618,57.262
					c0.065,5.996,5.188,10.528,11.448,10.556l643.63,2.864c6.373,0.029,11.383-4.501,11.177-10.552l-1.97-57.789
					C3156.342,2054.08,3151.091,2051.135,3144.736,2051.114z'
						/>
						<path
							fill='#FFFFFF'
							d='M3148.94,2175.91l-644.703-3.285c-6.27-0.031-11.308,4.033-11.243,10.056l0.624,57.764
					c0.065,6.049,5.198,9.458,11.485,9.498l646.466,3.977c6.4,0.04,11.473-3.338,11.264-9.443l-1.988-58.301
					C3160.639,2180.098,3155.323,2175.943,3148.94,2175.91z'
						/>
						<path
							fill='#FFFFFF'
							d='M3153.182,2301.804l-647.547-4.406c-6.297-0.042-11.345,5.226-11.279,11.301l0.629,58.273
					c0.066,6.103,5.257,12.715,11.572,12.765l649.424,5.15c6.43,0.051,11.417-6.541,11.207-12.701l-2.005-58.819
					C3164.973,2307.234,3159.593,2301.847,3153.182,2301.804z'
						/>
						<path
							fill='#FFFFFF'
							d='M3157.461,2428.808l-650.417-5.546c-6.324-0.054-11.381,6.437-11.315,12.566l0.635,58.79
					c0.066,6.156,5.267,11.643,11.61,11.705l652.31,6.303c6.458,0.063,11.507-5.377,11.295-11.591l-2.023-59.345
					C3169.346,2435.502,3163.9,2428.862,3157.461,2428.808z'
						/>
						<path
							fill='#FFFFFF'
							d='M3161.927,2561.375l-653.412-6.746c-6.353-0.066-11.467,3.272-11.401,9.455l0.641,59.313
					c0.067,6.211,5.279,10.554,11.649,10.626l655.222,7.477c6.488,0.074,11.6-4.191,11.386-10.461l-2.042-59.878
					C3173.758,2564.919,3168.396,2561.442,3161.927,2561.375z'
						/>
						<path
							fill='#FFFFFF'
							d='M3166.283,2690.685l-656.333-7.928c-6.382-0.077-11.506,4.488-11.438,10.727l0.647,59.843
					c0.067,6.267,5.288,9.444,11.688,9.528l658.16,8.671c6.518,0.086,11.694-2.983,11.478-9.31l-2.06-60.418
					C3178.21,2695.499,3172.782,2690.764,3166.283,2690.685z'
						/>
						<path
							fill='#FFFFFF'
							d='M3170.678,2821.151l-659.281-9.13c-6.41-0.089-11.544,5.726-11.476,12.021l0.653,60.38
					c0.068,6.323,5.349,12.816,11.778,12.913l661.227,9.928c6.549,0.098,11.636-6.299,11.418-12.683l-2.078-60.966
					C3182.702,2827.258,3177.207,2821.241,3170.678,2821.151z'
						/>
					</g>
				</g>
				<g>
					<g>
						<path
							fill='#BDD0FB'
							d='M1604.793,2961.818c0,0,236.052-1448.505-368.322-2486.374
					c-7.88-13.532-27.063-14.517-36.141-1.758C1056.944,675.222,207.053,1986.215,1604.793,2961.818z'
						/>
						<path
							opacity='0.52;'
							fill='#FFFFFF'
							d='M1182.397,897.547c0,0-0.872,7.955-2.087,22.955
					c-1.251,14.99-2.83,37.001-4.417,65.044c-1.7,28.036-3.022,62.124-4.147,101.225c-0.332,19.562-0.972,40.365-0.994,62.31
					c-0.092,10.97-0.185,22.22-0.279,33.738c0.042,11.518,0.085,23.304,0.127,35.339c-0.02,12.038,0.163,24.323,0.425,36.841
					c0.225,12.518,0.457,25.274,0.691,38.245c0.428,12.968,0.862,26.155,1.303,39.545c0.22,6.698,0.444,13.442,0.668,20.238
					c0.322,6.789,0.646,13.631,0.973,20.515c0.673,13.775,1.352,27.733,2.041,41.863c0.781,14.128,1.817,28.412,2.73,42.859
					c1.675,28.905,4.205,58.378,6.567,88.364c1.058,15.003,2.8,30.074,4.213,45.283c1.537,15.195,2.884,30.521,4.605,45.903
					c1.806,15.369,3.623,30.835,5.449,46.377c0.913,7.769,1.827,15.561,2.745,23.37c1.071,7.789,2.146,15.594,3.22,23.412
					c2.16,15.643,4.327,31.344,6.502,47.095c2.17,15.747,4.933,31.455,7.379,47.242c2.591,15.764,4.894,31.609,7.733,47.376
					c2.871,15.76,5.744,31.54,8.618,47.317l4.255,23.673c1.439,7.887,3.163,15.715,4.729,23.569
					c3.207,15.698,6.411,31.38,9.608,47.03c3.024,15.689,6.82,31.175,10.347,46.674c3.57,15.489,7.129,30.933,10.674,46.315
					c7.554,30.652,15.64,60.912,23.303,90.892c8.589,29.728,17.072,59.094,25.416,87.979c9.147,28.64,18.14,56.801,26.944,84.367
					c9.587,27.305,18.967,54.019,28.098,80.024c9.624,25.822,19.576,50.72,28.884,74.929c2.369,6.039,4.621,12.061,7.029,17.962
					c2.508,5.855,4.993,11.662,7.456,17.413c4.925,11.505,9.758,22.792,14.492,33.849c4.734,11.061,9.369,21.887,13.901,32.471
					c4.891,10.424,9.667,20.604,14.323,30.528c9.309,19.859,18.14,38.696,26.438,56.399c8.948,17.383,17.322,33.653,25.066,48.698
					c3.872,7.524,7.586,14.741,11.136,21.639c3.561,6.89,6.848,13.523,10.414,19.571c27.571,48.944,43.325,76.911,43.325,76.911
					l0.042,0.078c0.591,1.048,0.22,2.377-0.828,2.969c-1.035,0.581-2.346,0.225-2.947-0.793c0,0-16.335-27.713-44.922-76.212
					c-3.671-6.012-7.069-12.606-10.744-19.457c-3.667-6.858-7.503-14.033-11.504-21.518c-7.999-14.967-16.652-31.152-25.897-48.45
					c-8.6-17.625-17.751-36.38-27.398-56.154c-4.794-9.905-9.711-20.062-14.745-30.459c-4.647-10.578-9.402-21.397-14.258-32.448
					c-4.856-11.051-9.813-22.334-14.866-33.833c-2.526-5.751-5.077-11.554-7.648-17.41c-2.474-5.898-4.791-11.92-7.227-17.955
					c-9.57-24.202-19.8-49.104-29.707-74.935c-9.413-26.018-19.085-52.745-28.97-80.066c-8.987-27.628-18.167-55.854-27.504-84.556
					c-8.468-28.974-17.078-58.427-25.793-88.243c-7.79-30.071-16.002-60.426-23.681-91.173c-3.607-15.427-7.229-30.92-10.862-46.455
					c-3.589-15.548-7.448-31.083-10.532-46.821c-3.259-15.698-6.525-31.429-9.794-47.177c-1.597-7.88-3.352-15.734-4.82-23.644
					l-4.347-23.748c-2.859-15.839-5.718-31.681-8.576-47.507c-2.799-15.832-5.059-31.743-7.608-47.569
					c-2.404-15.852-5.126-31.622-7.252-47.435c-2.131-15.809-4.255-31.573-6.371-47.271c-1.055-7.851-2.106-15.685-3.156-23.503
					c-0.895-7.838-1.788-15.656-2.678-23.454c-1.781-15.6-3.555-31.119-5.317-46.547c-1.677-15.437-2.978-30.815-4.471-46.063
					c-1.368-15.261-3.067-30.381-4.081-45.436c-2.224-30.087-4.82-59.645-6.193-88.658c-0.774-14.497-1.674-28.83-2.319-43.003
					c-0.552-14.18-1.099-28.183-1.638-42c-0.26-6.907-0.519-13.768-0.776-20.581c-0.158-6.812-0.315-13.579-0.472-20.293
					c-0.312-13.429-0.617-26.655-0.919-39.659c-0.109-13.007-0.216-25.796-0.32-38.346c-0.141-12.554-0.203-24.869-0.065-36.932
					c-0.029-48.261,1.38-92.489,2.869-131.661c1.752-39.169,3.622-73.296,5.772-101.365c1.979-28.079,4.161-50.093,5.699-65.099
					c1.54-15.003,2.588-23,2.588-23l0.003-0.023c0.157-1.192,1.251-2.035,2.444-1.878
					C1181.69,895.301,1182.525,896.372,1182.397,897.547z'
						/>
					</g>
					<g>
						<path
							fill='#91B3FA'
							d='M1390.856,3103.413c0,0-5.215-1690.338-802.923-2187.694
					c-14.156-8.826-32.851-2.077-38.189,13.729C487.643,1113.339,223.168,2111.731,1390.856,3103.413z'
						/>
						<path
							opacity='0.52;'
							fill='#FFFFFF'
							d='M654.63,1212.467c0,0,6.943,29.954,20.701,82.04
					c6.858,26.042,15.407,57.609,25.624,93.708c10.093,36.133,22.095,76.725,35.483,120.915
					c13.537,44.144,28.404,91.908,44.893,142.181c8.096,25.182,16.891,50.898,25.616,77.269
					c9.068,26.254,18.063,53.167,27.711,80.365c9.576,27.222,19.301,54.902,29.529,82.785
					c10.022,27.959,20.553,56.113,31.181,84.487c2.651,7.095,5.309,14.205,7.972,21.328c2.729,7.096,5.463,14.202,8.202,21.323
					c5.477,14.237,10.969,28.515,16.473,42.825c11.126,28.567,22.597,57.13,34.092,85.683
					c11.801,28.424,23.329,56.961,35.306,85.184c6.022,14.093,12.032,28.16,18.023,42.187c3.007,7.009,6.008,14.009,9.004,20.996
					c3.073,6.952,6.14,13.892,9.2,20.819c12.339,27.669,24.24,55.262,36.714,82.205c12.387,26.982,24.282,53.79,36.74,79.808
					c6.143,13.047,12.236,25.984,18.272,38.802c2.991,6.418,5.967,12.81,8.929,19.165c3.045,6.319,6.073,12.604,9.086,18.856
					c12.117,24.977,23.686,49.551,35.467,73.215c5.903,11.829,11.725,23.494,17.46,34.984c5.668,11.524,11.248,22.874,16.737,34.037
					c11.288,22.164,22.194,43.578,32.665,64.137c10.351,20.625,20.847,40.086,30.66,58.685
					c9.815,18.599,18.828,36.403,27.742,52.898c17.876,32.967,33.459,61.706,46.292,85.375
					c13.068,23.546,23.334,42.05,30.334,54.665c6.94,12.655,10.64,19.406,10.64,19.406l0.022,0.038
					c0.664,1.213,0.221,2.735-0.993,3.397c-1.177,0.647-2.648,0.243-3.34-0.893c0,0-4.006-6.58-11.516-18.916
					c-7.449-12.379-18.373-30.532-32.278-53.637c-7.075-11.487-14.321-24.538-22.401-38.639
					c-8.051-14.12-16.757-29.391-26.06-45.707c-18.261-32.824-38.79-69.917-60.127-110.937
					c-10.67-20.51-21.784-41.874-33.288-63.985c-5.595-11.136-11.285-22.462-17.062-33.959
					c-5.708-11.535-11.505-23.243-17.38-35.114c-23.062-47.694-47.393-97.991-71.054-150.722
					c-23.869-52.629-47.789-107.349-71.322-163.266c-12.013-27.851-23.224-56.304-35.112-84.598
					c-11.717-28.367-22.984-57.046-34.523-85.612c-11.196-28.703-22.393-57.406-33.545-85.994
					c-5.46-14.339-10.909-28.653-16.344-42.922c-2.717-7.133-5.429-14.256-8.138-21.367c-2.641-7.138-5.275-14.263-7.906-21.372
					c-10.541-28.435-20.985-56.651-30.922-84.664c-10.141-27.941-19.782-55.676-29.273-82.953
					c-9.563-27.249-18.475-54.217-27.461-80.519c-8.643-26.419-17.356-52.183-25.373-77.413
					c-16.332-50.365-31.049-98.215-44.446-142.436c-13.203-44.28-25.145-84.919-35.314-121.06
					c-10.243-36.12-18.815-67.711-25.693-93.779c-13.791-52.113-20.775-82.163-20.775-82.163c-0.318-1.347,0.517-2.695,1.861-3.012
					c1.346-0.318,2.693,0.517,3.012,1.861L654.63,1212.467z'
						/>
					</g>
				</g>
				<g>
					<path
						fill='#91B3FA'
						d='M2774.155,1398.118c-0.173-7.593-3.479-14.775-9.134-19.844
				c-5.655-5.069-13.156-7.572-20.721-6.916l-764.217,66.197c-14.209,1.232-25.558,13.124-25.558,27.387V3001.2
				c0,15.182,13.191,26.345,28.373,26.345h800.26c7.399,0,14.485-2.41,19.658-7.702c5.172-5.292,7.992-12.157,7.823-19.555
				L2774.155,1398.118z'
					/>
					<g opacity='0.68'>
						<path
							fill='#BDD0FB'
							d='M2105.888,1513.326l-58.33,3.95c-6.393,0.419-9.668,6.323-9.668,13.188v1401.33
					c0,7.009,1.425,15.338,7.886,15.338h60.876c6.5,0,11.841-7.028,11.836-14.064l-0.874-1407.372
					C2117.611,1518.805,2112.32,1512.904,2105.888,1513.326z'
						/>
						<path
							opacity='0.7;'
							fill='#BDD0FB'
							d='M2247.391,1504.047l-61.016,4.001c-6.476,0.425-12.721,6.435-12.715,13.344
					l1.463,1411.643c0.007,7.055,6.333,14.098,12.877,14.098h61.675c6.586,0,10.967-7.065,10.954-14.148l-2.359-1416.432
					C2258.26,1509.618,2253.907,1503.62,2247.391,1504.047z'
						/>
						<path
							fill='#BDD0FB'
							d='M2390.742,1494.647l-61.815,4.054c-6.56,0.43-10.341,6.315-10.326,13.269l3.002,1420.98
					c0.015,7.102,3.862,14.183,10.494,14.183h62.489c6.674,0,13.693-7.103,13.674-14.234l-3.923-1425.841
					C2404.318,1500.078,2397.343,1494.214,2390.742,1494.647z'
						/>
						<path
							fill='#BDD0FB'
							d='M2535.975,1485.124l-62.628,4.106c-6.646,0.436-11.503,6.428-11.481,13.427l4.544,1430.208
					c0.023,7.149,4.969,14.268,11.689,14.268h63.321c6.762,0,12.819-7.142,12.791-14.319l-5.488-1435.141
					C2548.697,1490.646,2542.663,1484.685,2535.975,1485.124z'
						/>
						<path
							fill='#BDD0FB'
							d='M2683.129,1475.474l-63.458,4.161c-6.734,0.442-12.694,6.543-12.664,13.587l6.126,1439.557
					c0.031,7.196,6.107,14.353,12.916,14.353h64.17c6.853,0,11.919-7.18,11.883-14.405l-7.096-1444.563
					C2694.972,1481.092,2689.906,1475.03,2683.129,1475.474z'
						/>
					</g>
				</g>
				<g>
					<path
						fill='#BDD0FB'
						d='M2175.835,1717.511c-0.112-8.027-3.461-15.67-9.288-21.191c-5.829-5.521-13.64-8.455-21.663-8.133
				l-609.573,24.439c-10.463,0.419-18.655,9.152-18.407,19.621l30.943,1303.131l616.367-7.52c7.901-0.096,15.439-3.331,20.954-8.991
				c5.514-5.659,8.552-13.28,8.442-21.181L2175.835,1717.511z'
					/>
					<g opacity='0.5'>
						<g>
							<polygon
								fill='#FFFFFF'
								points='1684.907,1957.692 1586.815,1957.718 1585.656,1790.07 1683.964,1790.07 					'
							/>
							<polygon
								fill='#FFFFFF'
								points='1825.714,1957.381 1727.987,1957.406 1727.139,1790.07 1825.079,1790.07 					'
							/>
							<polygon
								fill='#FFFFFF'
								points='1973.775,1957.054 1876.432,1957.077 1875.908,1790.07 1973.463,1790.07 					'
							/>
							<polygon
								fill='#FFFFFF'
								points='2121.493,1957.119 2022.466,1956.95 2020.2,1790.07 2121.493,1790.07 					'
							/>
						</g>
						<g>
							<polygon
								fill='#FFFFFF'
								points='1686.247,2195.729 1588.462,2196.253 1587.31,2029.836 1685.31,2029.527 					'
							/>
							<polygon
								fill='#FFFFFF'
								points='1826.616,2194.978 1729.193,2195.5 1728.35,2029.391 1825.985,2029.083 					'
							/>
							<polygon
								fill='#FFFFFF'
								points='1974.219,2194.188 1877.177,2194.707 1876.656,2028.922 1973.908,2028.616 					'
							/>
							<polygon
								fill='#FFFFFF'
								points='2121.493,2193.421 2022.765,2193.939 2023.583,2028.469 2121.493,2028.163 					'
							/>
						</g>
						<g>
							<polygon
								fill='#FFFFFF'
								points='1687.624,2439.763 1590.153,2440.6 1589.009,2275.246 1686.693,2274.62 					'
							/>
							<polygon
								fill='#FFFFFF'
								points='1827.543,2438.562 1730.432,2439.395 1729.594,2274.346 1826.916,2273.723 					'
							/>
							<polygon
								fill='#FFFFFF'
								points='1974.674,2437.299 1877.942,2438.129 1877.424,2273.4 1974.366,2272.78 					'
							/>
							<polygon
								fill='#FFFFFF'
								points='2121.493,2436.074 2023.073,2436.902 2023.892,2272.483 2121.493,2271.865 					'
							/>
						</g>
						<g>
							<polygon
								fill='#FFFFFF'
								points='1688.97,2678.344 1591.806,2679.484 1590.696,2519.088 1688.067,2518.151 					'
							/>
							<polygon
								fill='#FFFFFF'
								points='1828.449,2676.707 1731.643,2677.843 1730.83,2517.74 1827.841,2516.807 					'
							/>
							<polygon
								fill='#FFFFFF'
								points='1975.119,2674.985 1878.69,2676.117 1878.188,2516.323 1974.82,2515.393 					'
							/>
							<polygon
								fill='#FFFFFF'
								points='2121.493,2673.315 2023.373,2674.443 2024.199,2514.947 2121.493,2514.021 					'
							/>
						</g>
						<g>
							<polygon
								fill='#FFFFFF'
								points='1690.329,2919.31 1593.476,2920.756 1592.373,2761.377 1689.431,2760.133 					'
							/>
							<polygon
								fill='#FFFFFF'
								points='1829.364,2917.235 1732.866,2918.675 1732.058,2759.587 1828.76,2758.347 					'
							/>
							<polygon
								fill='#FFFFFF'
								points='1975.569,2915.053 1879.445,2916.487 1878.946,2757.703 1975.272,2756.469 					'
							/>
							<polygon
								fill='#FFFFFF'
								points='2121.493,2912.936 2023.677,2914.365 2024.504,2755.877 2121.493,2754.646 					'
							/>
						</g>
					</g>
				</g>
				<g>
					<path
						fill={theme.themeIcon}
						d='M1682.742,2283.968h-920.19
				c-11.966,0-21.666-9.7-21.666-21.666V1626.78c0-11.966,9.7-21.666,21.666-21.666h920.19c11.966,0,21.666,9.7,21.666,21.666
				v635.521C1704.408,2274.267,1694.708,2283.968,1682.742,2283.968z'
					/>
					<path
						fill={theme.starColor}
						d='M1720.189,2283.968h-920.19
				c-11.966,0-21.666-9.7-21.666-21.666V1626.78c0-11.966,9.7-21.666,21.666-21.666h920.19c11.966,0,21.666,9.7,21.666,21.666
				v635.521C1741.856,2274.267,1732.155,2283.968,1720.189,2283.968z'
					/>
					<path
						fill='#FFFFFF'
						d='M1053.355,2194.579c-7.41,0-13.669-0.09-18.629-0.189c-8.466-0.169-15.412-6.404-16.493-14.82
				c-1.086-8.475,4.098-16.303,12.325-18.624c44.03-12.34,92.04-33.314,127.391-50.295
				c-168.592-13.744-202.25-100.331-208.754-139.939c-16.413-99.883,70.278-212.902,193.242-251.943
				c125.483-39.808,252.59-25.466,323.869,36.561c37.875,32.966,78.958,96.088,59.587,209.685
				C1489.661,2177.548,1158.218,2194.579,1053.355,2194.579z M1038.865,2190.386h0.01H1038.865z M1035.359,2163.794h0.04
				C1035.399,2163.794,1035.374,2163.794,1035.359,2163.794z M1278.94,2080.683l-53.412,29.779
				c-2.814,1.563-49.827,27.637-107.004,51.55c130.159-7.848,350.361-44.618,377.212-202.136
				c13.624-79.954-3.028-141.015-49.508-181.46c-72.106-62.764-199.87-60.513-294.534-30.486
				c-107.208,34.041-186.116,133.784-172.312,217.822c12.534,76.289,99.41,118.965,238.418,116.047L1278.94,2080.683z'
					/>
				</g>
				<g>
					<path
						fill={theme.darkNumberColor}
						d='M1694.497,1485.756h-920.19
				c-11.966,0-21.666-9.7-21.666-21.666V828.569c0-11.966,9.7-21.666,21.666-21.666h920.19c11.966,0,21.666,9.7,21.666,21.666
				v635.521C1716.163,1476.056,1706.463,1485.756,1694.497,1485.756z'
					/>
					<path
						fill={theme.numberColor}
						d='M1731.809,1489.1h-920.19
				c-11.966,0-21.666-9.7-21.666-21.666V831.913c0-11.966,9.7-21.666,21.666-21.666h920.19c11.966,0,21.666,9.7,21.666,21.666
				v635.521C1753.475,1479.4,1743.775,1489.1,1731.809,1489.1z'
					/>
					<g>
						<path
							fill='#DAE3FE'
							d='M1483.18,1389.577h-454.142
					c-37.213,0-67.486-30.273-67.486-67.486V971.368c0-37.213,30.273-67.486,67.486-67.486h454.142
					c37.213,0,67.486,30.273,67.486,67.486v350.723C1550.666,1359.304,1520.393,1389.577,1483.18,1389.577z M1029.038,927.29
					c-24.304,0-44.078,19.774-44.078,44.078v350.723c0,24.304,19.774,44.078,44.078,44.078h454.142
					c24.304,0,44.078-19.774,44.078-44.078V971.368c0-24.304-19.774-44.078-44.078-44.078H1029.038z'
						/>
						<polygon
							fill='#E9EFFD'
							points='1255.049,1147.998 
					981.293,942.629 995.342,923.904 1255.434,1119.025 1522.111,929.86 1535.657,948.952 				'
						/>
						<polygon
							fill='#E9EFFD'
							points='1518.692,1367.775 
					1348.387,1065.96 1368.772,1054.458 1539.076,1356.274 				'
						/>
						<polygon
							fill='#DAE3FE'
							points='994.757,1365.068 
					974.262,1353.762 1141.64,1050.323 1162.136,1061.629 				'
						/>
					</g>
				</g>
				<g>
					<path
						fill={theme.darkNumberColor}
						d='M1682.742,3100.823h-920.19
				c-11.966,0-21.666-9.7-21.666-21.666v-635.521c0-11.966,9.7-21.666,21.666-21.666h920.19c11.966,0,21.666,9.7,21.666,21.666
				v635.521C1704.408,3091.123,1694.708,3100.823,1682.742,3100.823z'
					/>
					<path
						fill={theme.numberColor}
						d='M1720.189,3100.823h-920.19
				c-11.966,0-21.666-9.7-21.666-21.666v-635.521c0-11.966,9.7-21.666,21.666-21.666h920.19c11.966,0,21.666,9.7,21.666,21.666
				v635.521C1741.856,3091.123,1732.155,3100.823,1720.189,3100.823z'
					/>
					<path
						fill='#DAE3FE'
						d='M1410.877,3003.479
				c-37.576,0-81.751-17.619-131.691-52.578h-0.003c-33.023-23.125-177.133-167.235-200.255-200.255
				c-41.658-59.518-58.689-110.849-50.616-152.575c5.215-26.972,21.227-49.874,46.302-66.232l21.923-14.307
				c16.247-10.584,38.225-6.087,48.992,10.045l60.54,90.817c12.446,18.647,7.88,43.457-10.391,56.481l-0.421,0.284l-10.437,6.773
				c-9.464,6.149-15.457,16.129-16.436,27.38c-0.976,11.244,3.2,22.102,11.459,29.787l0.3,0.294l110.584,110.594
				c7.694,8.262,18.562,12.433,29.793,11.466c11.25-0.977,21.237-6.969,27.393-16.44l7.048-10.839
				c13.01-18.291,37.83-22.857,56.491-10.398l90.807,60.534c16.142,10.771,20.649,32.749,10.052,48.992l-14.307,21.929
				c-16.358,25.071-39.261,41.083-66.232,46.298C1425.053,3002.829,1418.084,3003.479,1410.877,3003.479z M1292.608,2931.724
				c54.126,37.892,99.468,53.646,134.715,46.824c20.897-4.043,38.082-16.192,51.073-36.109l14.307-21.929
				c3.618-5.545,2.08-13.05-3.432-16.727l-90.807-60.534c-8.024-5.353-18.644-3.442-24.297,4.314l-6.623,10.202
				c-10.12,15.564-26.518,25.404-44.999,27.008c-18.402,1.594-36.174-5.212-48.8-18.677l-110.013-110.01
				c-13.465-12.625-20.267-30.397-18.67-48.803c1.607-18.481,11.453-34.878,27.011-44.986l10.202-6.619
				c7.753-5.666,9.66-16.293,4.321-24.297l-60.54-90.813c-3.674-5.503-11.185-7.038-16.73-3.429l-21.92,14.307
				c-19.921,12.991-32.07,30.175-36.112,51.073c-6.819,35.26,8.928,80.579,46.811,134.702
				c21.707,31.001,163.499,172.793,194.507,194.504H1292.608z'
					/>
				</g>
				<g>
					<g>
						<g>
							<path
								fill={theme.dark}
								d='M2393.394,2395.31
						c-0.36,0.018-0.722,0.028-1.087,0.025l-472.595-0.48c-11.682-0.01-21.142-9.661-21.129-21.554
						c0.012-11.897,9.528-21.727,21.179-21.514l472.596,0.481c11.682,0.009,21.141,9.66,21.129,21.553
						C2413.474,2385.344,2404.572,2394.747,2393.394,2395.31z'
							/>
						</g>
						<g>
							<path
								fill={theme.dark}
								d='M1736.691,1923.275
						c0.169,0.327,0.33,0.656,0.481,0.995l198.848,437.262c4.919,10.808,0.327,23.625-10.253,28.628
						c-10.583,5.006-23.335,0.35-28.058-10.515l-198.849-437.261c-4.918-10.809-0.326-23.626,10.253-28.629
						C1719.364,1908.909,1731.476,1913.177,1736.691,1923.275z'
							/>
						</g>
					</g>
					<g>
						<g>
							<path
								fill={theme.imageClothes}
								d='M2438.845,3098.121
						c0,0,4.136,22.969,5.77,50.236c0.697,11.618-7.914,21.714-19.499,22.837c-40.342,3.912-132.168,11.137-190.801,2.676
						c-8.213-1.185-11.476-11.274-5.405-16.931c24.177-22.525,82.563-66.339,178.664-75.917L2438.845,3098.121z'
							/>
							<path
								fill={theme.skinColor}
								d='M2458.9,3046.347l-20.396,49.993
						c-2.795,6.851-9.745,11.11-17.104,10.34c-4.994-0.522-10.908-1.48-16.661-3.227c-11.51-3.494-18.002-15.695-14.557-27.22
						l15.394-51.492L2458.9,3046.347z'
							/>
							<path
								fill={theme.imageClothes}
								d='M2807.607,3137.938
						c0,0-40.843,34.173-56.756,78.007c-4.377,12.056,4.874,24.756,17.7,24.756h84.049c12.302,0,21.733-11.066,19.59-23.18
						C2866.836,3187.253,2851.398,3138.065,2807.607,3137.938z'
							/>
							<path
								fill={theme.skinColor}
								d='M2858.203,3093.464
						c0,0-8.25,36.516-14.592,60.647c-1.151,4.38-5.824,6.783-10.09,5.259c-12.72-4.547-23.069-6.559-30.594-5.549
						c-3.478,0.466-6.515-2.427-6.56-5.935l-0.791-60.65L2858.203,3093.464z'
							/>
							<path
								fill={theme.dark}
								d='M2640.985,2271.8l-248.427,757.886
						c0,0,47.65,31.206,70.351,29.906l295.074-609.084c0,0,51.635,375.297,26.615,644.731l84.517,6.448
						c0,0,137.427-688.542,33.336-924.372L2640.985,2271.8z'
							/>
						</g>
						<path
							fill={theme.accentBright}
							d='M2594.409,1604.899
					c0,0,201.273,17.873,172.801,161.284c0,0,111.259,209.376,163.274,456.02c1.693,8.025-3.684,15.854-11.768,17.238
					l-335.187,57.39c-9.246,1.583-18.164-4.183-20.513-13.265l-80.087-309.521l-31.208,143.907l-99.162-25.837l83.478-422.867
					C2436.037,1669.247,2447.683,1607.55,2594.409,1604.899z'
						/>
						<path
							fill={theme.accentColor}
							d='M2481.225,1960.201
					c0.175,0,0.353-0.017,0.533-0.056c1.345-0.295,2.2-1.623,1.908-2.97l-27.94-128.293c-0.295-1.345-1.606-2.193-2.97-1.908
					c-1.345,0.295-2.201,1.623-1.908,2.97l27.94,128.293C2479.044,1959.404,2480.076,1960.201,2481.225,1960.201z'
						/>
						<path
							fill={theme.skinColor}
							d='M2501.457,2341.964
					c10.559-5.805,22.919-7.413,34.612-4.503l23.32,5.805c4.958,1.234,9.868-2.179,10.439-7.256l24.134-214.804
					c30.175,14.329,73.71,12.617,88.066,11.568l-55.545,253.236h-194.374L2501.457,2341.964z'
						/>
						<path
							fill={theme.skinColor}
							d='M1985.009,1876.031l5.875-19.525
					c3.382-11.236,11.688-20.326,22.574-24.704l106.257-42.731c0,0,1.604-13.628-22.451-22.888
					c-24.055-9.26-18.432-36.201-18.432-36.201s32.865,14.992,80.974,38.768c27.516,13.598,42.953,37.606,51.139,55.677
					l240.774,293.524l-28.269,19.424c-37.172,25.541-88.298,12.994-109.422-26.854L2183.594,1860.4
					C2118.106,1880.073,2051.839,1883.835,1985.009,1876.031z'
						/>
						<g>
							<path
								fill={theme.skinColor}
								d='M2529.204,1467.969l61.906,173.259
						c0,0-54.818,42.856-94.821,28.365l-31.195-99.316C2465.094,1570.277,2488.92,1448.893,2529.204,1467.969z'
							/>
							<g>
								<path
									fill={theme.skinColor}
									d='M2344.445,1405.746
							c0,2.67,59.633,168.812,59.633,168.812l94.14-36.066l-33.124-165.865C2465.094,1372.627,2344.445,1361.05,2344.445,1405.746z'
								/>
								<path
									fill={theme.dark}
									d='M2448.581,1413.035l24.275,85.949
							c0.311,1.102,0.47,2.212,0.472,3.358c0.01,7.01-1.726,31.131-26.296,27.851c-22.294-2.977-42.686-5.362-55.652,0.745
							c-5.822,2.742-8.607,9.442-6.431,15.498c5.422,15.093,18.268,48.88,33.909,76.531c3.174,5.611,10.135,7.793,16.003,5.124
							c17.681-8.044,54.496-26.053,80.719-47.479c3.553-2.903,5.26-7.469,4.414-11.979c-4.126-21.987-16.416-88.858-16.958-108.023
							c-0.509-17.988,19.675-31.719,28.561-36.881c2.913-1.693,4.986-4.479,5.775-7.755c2.789-11.578,5.157-37.781-28.266-43.555
							c-6.995-1.208-11.556-7.904-10.136-14.859c2.828-13.846,1.924-34.223-24.371-41.728c-26.727-7.628-43.544,8.459-52.097,20.795
							c-3.458,4.988-9.93,6.606-15.471,4.13c-3.155-1.409-6.967-1.472-11.062,1.981c-5.519,4.653-13.921,3.237-17.977-2.734
							c-7.793-11.471-21.951-22.922-45.919-15.166c-42.532,13.761-40.499,62.798-24.908,89.457
							c15.59,26.658,60.855,9.117,82.283-8.548c16.292-13.431,41.099-5.397,52.054-0.774
							C2444.971,1406.437,2447.557,1409.41,2448.581,1413.035z'
								/>
								<path
									fill={theme.skinColor}
									d='M2511.561,1491.992
							c0,0-25.171-47.617-8.526-62.569c16.646-14.953,44.847-12.506,44.087,24.73
							C2546.179,1500.468,2511.561,1491.992,2511.561,1491.992z'
								/>
							</g>
						</g>
						<path
							fill={theme.accentColor}
							d='M2662.495,2135.998
					c13.737,0,23.542-1.043,24.602-1.16l1.786-0.2l73.289-341.702c0.29-1.347-0.568-2.675-1.915-2.963
					c-1.363-0.299-2.674,0.57-2.963,1.916l-72.529,338.156c-10.907,0.958-69.152,4.825-99.899-16.958l63.489-296.872l-13.251-24.972
					c-0.646-1.219-2.157-1.682-3.375-1.036c-1.219,0.648-1.682,2.157-1.036,3.375l12.404,23.369l-63.781,298.238l1.274,0.957
					C2602.806,2132.838,2638.592,2135.998,2662.495,2135.998z'
						/>
					</g>
					<g>
						<path
							fill={theme.ironElements}
							d='M2261.482,2435.403c1.917,6.502,184.939,568.78,231.342,711.327
					c5.347,16.428-9.587,32.559-30.114,32.559c-14.313,0-26.786-8.077-30.253-19.589l-203.168-674.755L2261.482,2435.403z'
						/>
						<path
							fill={theme.ironElements}
							d='M2161.26,2435.403c-1.917,6.502-184.939,568.78-231.342,711.327
					c-5.348,16.428,9.587,32.559,30.112,32.559c14.313,0,26.787-8.077,30.254-19.589l203.168-674.755L2161.26,2435.403z'
						/>
						<path
							fill={theme.ironElements}
							d='M2153.633,2412.957h113.987v91.54c0,13.156-10.537,23.823-23.537,23.823h-15.636v205.614
					c0,9.96-7.978,18.034-17.82,18.034s-17.82-8.074-17.82-18.034V2528.32h-7.935c-17.253,0-31.24-14.156-31.24-31.617V2412.957z'
						/>
						<path
							fill={theme.ironElements}
							d='M2615.869,2390.97h-797.581c-11.146,0-20.881,6.092-23.668,14.809l-11.135,34.846
					c-4.008,12.541,7.772,24.748,23.809,24.673l818.401-3.799c15.862-0.073,27.438-12.141,23.563-24.563l-9.685-31.046
					C2636.836,2397.117,2627.069,2390.97,2615.869,2390.97z'
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default ContactImg;
