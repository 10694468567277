import React from "react";

const IKLogoImg = () => (
	<svg viewBox='0 0 172 85'>
		<g id='Layer_2' data-name='Layer 2'>
			<g id='Layer_1-2' data-name='Layer 1'>
				<path
					d='M82.86,49.4h.48V17.56a7.23,7.23,0,0,0-2.13-5.14L70.93,2.13A7.27,7.27,0,0,0,65.79,0H7.27A7.28,7.28,0,0,0,0,7.27V67.48a7.27,7.27,0,0,0,2.13,5.14L12.42,82.91A7.27,7.27,0,0,0,17.56,85H76.07a7.27,7.27,0,0,0,7.27-7.27V64.71h-.48a4.78,4.78,0,0,1-4.79-4.79V54.18A4.78,4.78,0,0,1,82.86,49.4Z'
					fill='#d37428'
				/>
				<rect
					x='16.76'
					y='14.26'
					width='10.05'
					height='9.83'
					rx='3.56'
					fill='#fff'
				/>
				<path
					d='M33.72,62.21a1.93,1.93,0,0,1-2.06,2.14H28.24c-7.63,0-10-2.64-10-9V35.42H13.91A1.77,1.77,0,0,1,12,33.57V30.36a1.77,1.77,0,0,1,1.92-1.85h9.91c2,0,3,.85,3,3V54.87c0,1.78.57,2.43,2.14,2.43H31.8a1.79,1.79,0,0,1,1.92,1.92Z'
					fill='#fff'
				/>
				<path
					d='M48.54,61.86a2.36,2.36,0,0,1-2.49,2.49H42.49A2.36,2.36,0,0,1,40,61.86V17.18a3.26,3.26,0,0,1,3.49-3.49h1.57a3.25,3.25,0,0,1,3.49,3.49Z'
					fill='#fff'
				/>
				<path
					d='M69.56,64a4.44,4.44,0,0,1-6.06-.92L53,49.89a5.44,5.44,0,0,1-1.42-3.71A5.65,5.65,0,0,1,53,42.48l8.91-11.83c1.14-1.57,2-2.14,3.77-2.14h3.21c1.5,0,2.28.64,2.28,1.92,0,.86-.21,1.5-1.35,2.93L60.09,45.82l10.11,12A4,4,0,0,1,69.56,64Z'
					fill='#fff'
				/>
				<path
					d='M171.54,7.27V67.48a7.27,7.27,0,0,1-2.13,5.14L159.12,82.91A7.27,7.27,0,0,1,154,85H95.47a7.28,7.28,0,0,1-7.28-7.27V64.71h.41a4.78,4.78,0,0,0,4.78-4.79V54.18A4.78,4.78,0,0,0,88.6,49.4h-.41V17.56a7.27,7.27,0,0,1,2.13-5.14L100.61,2.13A7.27,7.27,0,0,1,105.75,0h58.52A7.28,7.28,0,0,1,171.54,7.27Z'
					fill='#004b87'
				/>
				<path
					d='M128.77,41.83V51c0,4.71-2.63,7.34-7.62,7.34s-7.63-2.63-7.63-7.34V41.83c0-4.7,2.64-7.34,7.63-7.34S128.77,37.13,128.77,41.83Zm8.69.79c0-9-6.05-14.89-15.24-14.89-5.13,0-8.91,1.7-11.12,5l-3.63-3.63a2.91,2.91,0,0,0-4.42-.14l-1.43,1.42a2.91,2.91,0,0,0,.15,4.42L105,38v37.9a3.26,3.26,0,0,0,3.5,3.49H110a3.25,3.25,0,0,0,3.49-3.49V62.07c2.14,2,5.21,3.06,9,3.06,9,0,15-5.84,15-14.89Z'
					fill='#fff'
				/>
				<path
					d='M159.05,59.22a1.79,1.79,0,0,0-1.92-1.92h-2.85c-1.57,0-2.14-.65-2.14-2.43V17.18a3.25,3.25,0,0,0-3.49-3.49h-1.57a3.25,3.25,0,0,0-3.49,3.49V55.37c0,6.34,2.35,9,10,9H157a1.93,1.93,0,0,0,2.06-2.14Z'
					fill='#fff'
				/>
			</g>
		</g>
		<script
			type='text/javascript'
			src='chrome-extension://hejbmebodbijjdhflfknehhcgaklhano/../window/testing-library.js'
		/>
	</svg>
);

export default IKLogoImg;
