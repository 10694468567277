import { useSelector } from "react-redux";
import { ThemeEnum, themes } from "../../../theme";
import { ObjectType } from "../../../types";

const FrontEndImg = () => {
	const themeState: ThemeEnum = useSelector(
		(state: ObjectType) => state.theme.value,
	);
	const theme = themes[themeState];
	return (
		<svg version='1.2' viewBox='0 0 500 500' width='100%' height='100%'>
			<g id='Window'>
				<g id='Window_00000158742406747169338900000016092748908615345814_'>
					<path
						stroke='#303030'
						strokeWidth={1}
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeMiterlimit={10}
						fill='#FFFFFF'
						d='
			M448.783,280.082H136.251c-6.427,0-11.637-5.21-11.637-11.637V54.437c0-6.427,5.21-11.637,11.637-11.637h312.531
			c6.427,0,11.637,5.21,11.637,11.637v214.008C460.42,274.872,455.21,280.082,448.783,280.082z'
					/>
					<g>
						<path
							stroke={theme.bikeColor}
							strokeWidth={1.5}
							strokeLinecap='round'
							strokeLinejoin='round'
							strokeMiterlimit={10}
							fill='#FFFFFF'
							d='
				M449.798,42.8H136.345c-6.173,0-11.176,5.004-11.176,11.176v9.205h335.806v-9.205C460.975,47.804,455.971,42.8,449.798,42.8z'
						/>
						<g>
							<path
								fill={theme.numberColor}
								d='M449.746,52.991c0,2.514-2.037,4.551-4.551,4.551c-2.513,0-4.551-2.037-4.551-4.551
					s2.038-4.551,4.551-4.551C447.709,48.44,449.746,50.477,449.746,52.991z'
							/>
							<path
								fill={theme.accentColor}
								d='M433.184,52.991c0,2.514-2.038,4.551-4.551,4.551c-2.513,0-4.551-2.037-4.551-4.551
					s2.038-4.551,4.551-4.551C431.146,48.44,433.184,50.477,433.184,52.991z'
							/>
							<path
								fill={theme.dark}
								d='M416.621,52.991c0,2.514-2.038,4.551-4.551,4.551c-2.513,0-4.551-2.037-4.551-4.551
					s2.038-4.551,4.551-4.551C414.584,48.44,416.621,50.477,416.621,52.991z'
							/>
						</g>
					</g>
					<g>
						<g>
							<path
								fill='#FFFFFF'
								d='M444.676,121.966H141.467c-2.457,0-4.449-1.992-4.449-4.448V81.351
					c0-2.457,1.992-4.448,4.449-4.448h303.209c2.457,0,4.448,1.992,4.448,4.448v36.167
					C449.125,119.975,447.133,121.966,444.676,121.966z'
							/>

							<path
								stroke={theme.bikeColor}
								strokeWidth={1.5}
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeDasharray='8.0531,5.0332'
								fill='none'
								d='
					M444.676,121.966H141.467c-2.457,0-4.448-1.992-4.448-4.448V81.351c0-2.457,1.992-4.448,4.448-4.448h303.209
					c2.457,0,4.448,1.992,4.448,4.448v36.167C449.125,119.975,447.133,121.966,444.676,121.966z'
							/>
						</g>
						<g>
							<path
								fill={theme.accentColor}
								d='M465.668,134.447H162.46c-2.457,0-4.448-1.992-4.448-4.449V93.831
					c0-2.457,1.992-4.448,4.448-4.448h303.209c2.457,0,4.449,1.992,4.449,4.448v36.167
					C470.117,132.455,468.125,134.447,465.668,134.447z'
							/>

							<path
								stroke='#303030'
								strokeWidth={1.5}
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit={10}
								fill='#FFFFFF'
								d='
					M454.618,118.577h-77.344c-3.68,0-6.662-2.983-6.662-6.662l0,0c0-3.68,2.983-6.662,6.662-6.662h77.344
					c3.68,0,6.662,2.983,6.662,6.662l0,0C461.281,115.594,458.298,118.577,454.618,118.577z'
							/>
							<path
								fill={theme.dark}
								d='M454.728,114.992l-1.588-1.588c0.438-0.575,0.702-1.289,0.702-2.066
					c0-1.884-1.532-3.416-3.416-3.416c-1.884,0-3.416,1.532-3.416,3.416c0,1.884,1.532,3.416,3.416,3.416
					c0.72,0,1.387-0.225,1.938-0.606l1.604,1.604c0.105,0.105,0.242,0.157,0.38,0.157c0.137,0,0.275-0.052,0.38-0.157
					C454.938,115.541,454.938,115.201,454.728,114.992z M450.427,113.679c-1.292,0-2.342-1.05-2.342-2.342
					c0-1.291,1.05-2.342,2.342-2.342c1.292,0,2.342,1.05,2.342,2.342C452.769,112.629,451.718,113.679,450.427,113.679z'
							/>
						</g>
					</g>
					<g>
						<g>
							<rect
								x='332.635'
								y='147.934'
								stroke={theme.bikeColor}
								strokeWidth={1.5}
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit={10}
								strokeDasharray='8,5'
								fill='#FFFFFF'
								width='104.187'
								height='85.511'
							/>
							<g>
								<rect
									x='381.566'
									y='144.091'
									stroke={theme.bikeColor}
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='#FFFFFF'
									width='8.046'
									height='7.686'
								/>

								<rect
									x='432.8'
									y='144.091'
									stroke={theme.bikeColor}
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='#FFFFFF'
									width='8.046'
									height='7.686'
								/>

								<rect
									x='328.613'
									y='144.091'
									stroke={theme.bikeColor}
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='#FFFFFF'
									width='8.046'
									height='7.686'
								/>

								<path
									stroke={theme.bikeColor}
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='#FFFFFF'
									d='
						M332.815,194.533h-0.359c-2.122,0-3.843-1.721-3.843-3.843l0,0c0-2.122,1.721-3.843,3.843-3.843h0.359
						c2.123,0,3.843,1.721,3.843,3.843l0,0C336.658,192.812,334.938,194.533,332.815,194.533z'
								/>

								<path
									stroke={theme.bikeColor}
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='#FFFFFF'
									d='
						M437.003,194.533h-0.359c-2.122,0-3.843-1.721-3.843-3.843l0,0c0-2.122,1.721-3.843,3.843-3.843h0.359
						c2.122,0,3.843,1.721,3.843,3.843l0,0C440.846,192.812,439.125,194.533,437.003,194.533z'
								/>
							</g>
							<g>
								<rect
									x='381.566'
									y='229.602'
									stroke={theme.bikeColor}
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='#FFFFFF'
									width='8.046'
									height='7.686'
								/>

								<rect
									x='432.8'
									y='229.602'
									stroke={theme.bikeColor}
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='#FFFFFF'
									width='8.046'
									height='7.686'
								/>

								<rect
									x='328.613'
									y='229.602'
									stroke={theme.bikeColor}
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='#FFFFFF'
									width='8.046'
									height='7.686'
								/>
							</g>
						</g>
						<g>
							<path
								fill={theme.numberColor}
								d='M435.96,243.68h-85.084c-5.275,0-9.552-4.276-9.552-9.551V167.72
					c0-5.275,4.276-9.552,9.552-9.552h85.084c5.275,0,9.551,4.276,9.551,9.552v66.408C445.511,239.404,441.235,243.68,435.96,243.68
					z'
							/>
							<g>
								<path
									stroke='#FFFFFF'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='#none'
									d='
						M359.664,215.186c3.914-16.917,17.542-29.401,33.753-29.401c16.211,0,29.839,12.484,33.753,29.401'
								/>

								<line
									stroke='#FFFFFF'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='#none'
									x1='359.664'
									y1='185.786'
									x2='427.171'
									y2='185.786'
								/>

								<rect
									x='389.934'
									y='182.302'
									stroke='#FFFFFF'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill={theme.numberColor}
									width='6.966'
									height='6.966'
								/>

								<rect
									x='356.181'
									y='211.703'
									stroke='#FFFFFF'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill={theme.numberColor}
									width='6.966'
									height='6.966'
								/>

								<rect
									x='423.687'
									y='211.703'
									stroke='#FFFFFF'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill={theme.numberColor}
									width='6.966'
									height='6.966'
								/>
								<path
									fill='#FFFFFF'
									d='M429.934,185.786c0,1.526-1.237,2.763-2.763,2.763c-1.526,0-2.763-1.237-2.763-2.763
						c0-1.526,1.237-2.763,2.763-2.763C428.697,183.022,429.934,184.26,429.934,185.786z'
								/>
								<path
									fill='#FFFFFF'
									d='M362.427,185.786c0,1.526-1.237,2.763-2.763,2.763c-1.526,0-2.763-1.237-2.763-2.763
						c0-1.526,1.237-2.763,2.763-2.763C361.19,183.022,362.427,184.26,362.427,185.786z'
								/>
							</g>
						</g>
					</g>
					<g>
						<g>
							<rect
								x='205.879'
								y='147.934'
								stroke={theme.bikeColor}
								strokeWidth={1.5}
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit={10}
								strokeDasharray='8,5'
								fill='#FFFFFF'
								width='104.187'
								height='85.511'
							/>
							<g>
								<rect
									x='254.81'
									y='144.091'
									stroke={theme.bikeColor}
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='#FFFFFF'
									width='8.046'
									height='7.686'
								/>

								<rect
									x='306.044'
									y='144.091'
									stroke={theme.bikeColor}
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='#FFFFFF'
									width='8.045'
									height='7.686'
								/>

								<rect
									x='201.857'
									y='144.091'
									stroke={theme.bikeColor}
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='#FFFFFF'
									width='8.046'
									height='7.686'
								/>

								<path
									stroke={theme.bikeColor}
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='#FFFFFF'
									d='
						M206.059,194.533H205.7c-2.122,0-3.843-1.721-3.843-3.843l0,0c0-2.122,1.721-3.843,3.843-3.843h0.359
						c2.122,0,3.843,1.721,3.843,3.843l0,0C209.902,192.812,208.181,194.533,206.059,194.533z'
								/>

								<path
									stroke={theme.bikeColor}
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='#FFFFFF'
									d='
						M310.246,194.533h-0.359c-2.122,0-3.843-1.721-3.843-3.843l0,0c0-2.122,1.721-3.843,3.843-3.843h0.359
						c2.123,0,3.843,1.721,3.843,3.843l0,0C314.089,192.812,312.369,194.533,310.246,194.533z'
								/>
							</g>
							<g>
								<rect
									x='254.81'
									y='229.602'
									stroke={theme.bikeColor}
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='#FFFFFF'
									width='8.046'
									height='7.686'
								/>

								<rect
									x='306.044'
									y='229.602'
									stroke={theme.bikeColor}
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='#FFFFFF'
									width='8.045'
									height='7.686'
								/>

								<rect
									x='201.857'
									y='229.602'
									stroke={theme.bikeColor}
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='#FFFFFF'
									width='8.046'
									height='7.686'
								/>
							</g>
						</g>
						<g>
							<path
								fill={theme.numberColor}
								d='M309.203,243.68h-85.084c-5.275,0-9.551-4.276-9.551-9.551V167.72
					c0-5.275,4.276-9.552,9.551-9.552h85.084c5.275,0,9.551,4.276,9.551,9.552v66.408
					C318.755,239.404,314.478,243.68,309.203,243.68z'
							/>
							<path
								stroke='#FFFFFF'
								strokeWidth={1.5}
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit={10}
								fill='#none'
								d='
					M304.329,233.288h-75.336c-2.419,0-4.38-1.961-4.38-4.38v-55.967c0-2.419,1.961-4.38,4.38-4.38h75.336
					c2.419,0,4.38,1.961,4.38,4.38v55.967C308.709,231.327,306.748,233.288,304.329,233.288z'
							/>
							<g>
								<path
									stroke='#FFFFFF'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='#none'
									d='
						M224.665,211.368l22.847-18.449c2.988-2.413,7.268-2.363,10.199,0.117l21.662,18.332'
								/>

								<path
									stroke='#FFFFFF'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='#none'
									d='
						M308.709,205.404l-14.369-8.382c-2.524-1.472-5.651-1.452-8.156,0.053l-13.859,8.329'
								/>

								<path
									stroke='#FFFFFF'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='#none'
									d='
						M278.643,186.935c0,2.559-2.074,4.633-4.633,4.633c-2.559,0-4.633-2.074-4.633-4.633c0-2.559,2.074-4.633,4.633-4.633
						C276.569,182.302,278.643,184.376,278.643,186.935z'
								/>
							</g>
						</g>
					</g>
				</g>
			</g>
			<g id='Code_Icon_00000129888860643316176100000014031709431365954743_'>
				<g id='Code_Icon'>
					<path
						stroke={theme.bikeColor}
						strokeWidth={1.5}
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeMiterlimit={10}
						fill='#FFFFFF'
						d='
			M410.865,333.816h-75.899c-6.17,0-11.171-5.002-11.171-11.171v-52.529c0-6.17,5.002-11.172,11.171-11.172h75.899
			c6.17,0,11.171,5.002,11.171,11.172v52.529C422.037,328.814,417.035,333.816,410.865,333.816z'
					/>
					<g id='Icon'>
						<path
							fill={theme.numberColor}
							d='M354.032,309.504l-22.102-8.832v-7.405l22.102-8.832c2.865-1.145,5.981,0.965,5.981,4.051v0
				c0,1.814-1.123,3.439-2.82,4.081l-17.686,6.682v-4.558l17.686,6.682c1.697,0.641,2.82,2.266,2.82,4.081l0,0
				C360.013,308.539,356.897,310.649,354.032,309.504z'
						/>
						<path
							fill={theme.numberColor}
							d='M363.647,314.62l9.782-39.126c0.486-1.942,2.23-3.304,4.232-3.304h0.292
				c2.838,0,4.92,2.667,4.232,5.42l-9.782,39.126c-0.486,1.942-2.23,3.304-4.232,3.304h-0.292
				C365.041,320.04,362.959,317.373,363.647,314.62z'
						/>
						<path
							fill={theme.numberColor}
							d='M385.818,288.486L385.818,288.486c0-3.086,3.116-5.196,5.981-4.051l22.102,8.832v7.405
				l-22.102,8.832c-2.865,1.145-5.981-0.965-5.981-4.051l0,0c0-1.814,1.123-3.439,2.82-4.081l17.686-6.682v4.558l-17.686-6.682
				C386.942,291.925,385.818,290.3,385.818,288.486z'
						/>
					</g>
				</g>
			</g>
			<g id='Character'>
				<g id='Bean_Bag'>
					<g>
						<path
							fill='#FFFFFF'
							d='M56.756,270.284C11.163,319.296,9.064,409.858,76.213,441.007
				c41.919,19.445,114.856,21.772,149.348-6.197c32.369-26.247,35.081-59.782,29.335-79.789
				c-6.715-23.381-16.84-31.51-31.827-45.881c-8.963-8.595-18.536-17.017-24.23-27.963c-1.929-3.708-3.372-7.641-5.211-11.393
				c-9.228-18.834-26.718-31.005-47.487-34.03c-10.172-1.482-20.597-1.072-30.671,0.889
				C92.736,241.071,72.091,253.381,56.756,270.284z'
						/>
					</g>

					<path
						stroke={theme.bikeColor}
						strokeWidth={1.5}
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeMiterlimit={10}
						fill='#FFFFFF'
						d='
			M56.756,270.284C11.163,319.296,9.064,409.858,76.213,441.007c41.919,19.445,113.279,19.706,149.348-6.197
			c31.73-22.786,35.081-59.782,29.335-79.789c-6.715-23.381-16.84-31.51-31.827-45.881c-8.963-8.595-18.536-17.017-24.23-27.963
			c-1.929-3.708-3.372-7.641-5.211-11.393c-9.228-18.834-26.718-31.005-47.487-34.03c-10.172-1.482-20.597-1.072-30.671,0.889
			C92.736,241.071,72.091,253.381,56.756,270.284z'
					/>
					<path
						stroke={theme.bikeColor}
						strokeWidth={1.5}
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeMiterlimit={10}
						fill='none'
						d='
			M106.256,238.661c-20.278,8.031-37.352,22.118-48.205,39.289c-10.853,17.17-16.457,36.657-18.686,56.237
			c-2.679,23.526-0.207,51.218,11.25,72.574c11.457,21.356,38.106,41.025,64.246,45.797'
					/>
					<path
						stroke={theme.bikeColor}
						strokeWidth={1.5}
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeMiterlimit={10}
						fill='none'
						d='
			M38.85,311.633c3.11-0.221,6.261,0.121,9.245,1.006'
					/>
					<path
						stroke={theme.bikeColor}
						strokeWidth={1.5}
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeMiterlimit={10}
						fill='none'
						d='
			M46.065,318.861c-3.05-0.278-6.122-0.335-9.181-0.171'
					/>
					<g>
						<path
							stroke={theme.bikeColor}
							strokeWidth={1.5}
							strokeLinecap='round'
							strokeLinejoin='round'
							strokeMiterlimit={10}
							fill='none'
							d='
				M72.126,361.34c21.358-3.668,44.321,3.662,59.307,18.931'
						/>
						<path
							stroke={theme.bikeColor}
							strokeWidth={1.5}
							strokeLinecap='round'
							strokeLinejoin='round'
							strokeMiterlimit={10}
							fill='none'
							d='
				M74.656,344.391c13.933,3.072,26.705,10.654,35.604,21.576'
						/>
					</g>
				</g>
				<g id='Character_00000087369876872916931030000006817137726545165238_'>
					<g id='Legs_00000052794516284618612530000005284071633843590017_'>
						<g>
							<g>
								<path
									fill={theme.skinColor}
									d='M263.536,453.133c-5.774-4.532-11.354-9.37-16.206-14.894
						c-4.836-5.505-9.855-9.179-6.462-18.702c3.393-9.523,5.085-13.003,8.759-22.563c8.158-21.225,10.932-25.518,14.867-40.836
						c3.922-15.268,4.931-31.196-17.537-37.276c-24.971-6.758-58.723-10.245-58.723-10.245H96.559c0,0,0.293,39.669,18.923,51.792
						c18.629,12.122,48.938,13.268,74.671,9.932c13.684-1.774,24.287-3.429,29.761-4.373c-1.595,4.373-4.297,11.836-5.616,27.533
						c-0.69,8.212-0.525,14.54-2.235,19.178c-1.166,3.162-7.333,10.399-9.885,14.967c-1.254,2.246-2.717,5.632-1.914,8.76
						c0.654,2.546,2.574,4.349,5.022,5.306c6.253,2.445,12.918,3.896,18.848,7.043c3.589,1.905,6.831,4.391,10.276,6.545
						c3.206,2.004,6.591,3.72,10.102,5.122c3.86,1.541,7.935,2.687,12.097,2.949c3.281,0.207,9.348,0.392,11.038-3.107
						C269.141,457.174,265.664,454.802,263.536,453.133z'
								/>
							</g>
							<path
								stroke='#303030'
								strokeWidth={1.5}
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit={10}
								fill='none'
								d='
					M229.564,351.006c-3.605,3.054-7.828,9.402-9.651,14.96'
							/>
							<g>
								<path
									stroke='#303030'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='#FFFFFF'
									d='
						M198.232,435.002c0,0-2.979,5.946-4.186,11.494c0,0,3.796,2.975,12.466,6.132c8.669,3.157,11.911,2.745,11.911,2.745
						l1.344-3.692c0,0,4.673,2.043,10.955,6.762c6.282,4.719,8.992,6.962,18.464,10.412c9.472,3.449,15.858,4.648,21.242,4.253
						c2.342,0.033,4.558-5.293,1.676-6.865C269.223,464.671,198.232,435.002,198.232,435.002z'
								/>
								<path
									fill={theme.dark}
									d='M218.319,423.364c3.873,2.491,10.693,1.027,10.693,1.027s4.7,9.453-1.094,9.806
						C224.36,434.413,220.966,431.272,218.319,423.364z'
								/>
								<path
									fill={theme.accentColor}
									d='M219.064,424.434c-0.992-1.52-2.106-2.893-3.731-3.876c-1.341-0.811-4.436-2.849-3.913-4.753
						c0.013-0.047,0.857-3.117,0.857-3.117c-4.248-1.597-5.155,3.583-7.793,8.382c-1.877,3.415-5.698,7.326-6.637,14.735
						c6.75,5.245,11.234,5.682,19.4,8.581c8.167,2.899,14.058,8.624,21.367,13.195c7.309,4.571,24.221,10.954,34.855,9.815
						c2.662-6.065,4.435-8.194-5.846-14.186c-10.281-5.991-15.459-12.185-18.079-15.675c-2.781-3.704-6.419-8.315-7.159-15.054
						c-0.1-0.912-0.671-1.734-1.547-2.008c-2.524-0.79-11.978-0.596-13.288,2.175c-1.007,2.129-0.399,4.564,0.049,6.753
						c0.141,0.689,0.868,2.072,0.529,2.703c-0.465,0.867-1.893,0.55-2.568,0.204c-2.225-1.141-3.723-3.303-5.012-5.445
						C220.051,426.038,219.574,425.216,219.064,424.434z'
								/>

								<path
									stroke='#303030'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='none'
									d='
						M227.689,422.504c-0.866,1.15-0.808,2.738-0.573,4.159c0.455,2.75,1.433,5.412,2.865,7.803
						c1.004,1.676,2.278,3.632,1.484,5.417c-0.522,1.173-1.827,1.827-3.101,1.988c-1.274,0.161-2.56-0.074-3.839-0.185
						c-1.418-0.123-2.85-0.094-4.262,0.088c-1.649,0.212-3.391,0.706-4.435,2'
								/>

								<path
									stroke='#303030'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='none'
									d='
						M273.468,467.397c2.662-6.065,4.435-8.194-5.846-14.186c-10.281-5.991-15.459-12.185-18.079-15.675
						c-2.781-3.704-6.419-8.315-7.159-15.054'
								/>

								<path
									stroke='#303030'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='none'
									d='
						M197.846,435.806c6.75,5.245,11.234,5.682,19.4,8.581c8.167,2.899,14.058,8.624,21.367,13.195
						c7.309,4.571,24.221,10.954,34.855,9.815'
								/>
							</g>
						</g>
						<g>
							<g>
								<path
									fill={theme.skinColor}
									d='M331.908,443.358c-1.469-1.899-4.118-2.325-6.506-2.576c-8.652-0.909-17.375-1.406-25.92-3.125
						c-6.87-1.382-14.567-2.871-17.755-10.074c-2.513-5.679-4.177-9.464-8.569-20.902c-4.074-10.613-9.618-27.914-18.302-52.2
						c-8.731-24.416-18.022-26.51-31.955-28.874c-21.414-3.634-34.587-5.471-70.467-7.964l-57.773,0.955
						c2.233,24.968,4.645,44.278,23.076,53.82c16.73,8.662,45.734,7.332,64.882,5.781c20.822-1.686,29.061-2.834,35.965-3.495
						c3.649,22.611,12.337,28.641,21.482,42.302c3.849,5.749,10.324,12.084,13.313,20.032c1.349,3.586,0.456,8.079,0.939,11.833
						c0.308,2.395,0.551,4.425,0.64,6.85c0.102,2.777,1.106,5.532,2.89,7.672c0.723,0.867,1.581,1.648,2.611,2.109
						c2.351,1.052,5.069,0.277,7.551-0.412c6.115-1.697,12.396-2.794,18.724-3.27c4.813-0.362,9.648-0.365,14.456-0.786
						c8.321-0.729,16.557-2.728,24.156-6.196c2.319-1.058,4.657-2.319,6.168-4.372C333.026,448.415,333.468,445.374,331.908,443.358
						z'
								/>
							</g>
							<g>
								<path
									stroke='#303030'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='#FFFFFF'
									d='
						M254.697,463.858c0,0,1.057,6.834,3.375,12.262c0,0,5.009,0.234,14.258-2.311s11.747-4.847,11.747-4.847l-1.084-3.938
						c0,0,5.191-1.083,13.357-0.867c8.166,0.216,11.814,0.485,21.919-2.297c10.105-2.782,16.239-5.612,20.564-9.19
						c2.005-1.382,0.675-7.231-2.714-6.827C332.73,446.245,254.697,463.858,254.697,463.858z'
								/>
								<path
									fill={theme.dark}
									d='M264.711,441.897c4.782-0.222,9.68-5.57,9.68-5.57s9.677,5.181,4.979,8.969
						C276.487,447.622,271.718,447.004,264.711,441.897z'
								/>
								<path
									fill={theme.accentColor}
									d='M265.987,442.355c-1.756-0.691-3.528-1.184-5.497-1.038c-1.625,0.12-5.476,0.257-6.178-1.672
						c-0.017-0.047-1.15-3.158-1.15-3.158c-4.562,1.206-2.211,6.141-1.556,11.798c0.466,4.025-0.417,9.64,3.25,16.485
						c8.879,0.38,12.943-1.951,21.61-4.412c8.667-2.461,17.108-1.158,26.055-1.686c8.947-0.528,27.124-5.304,35.451-12.674
						c-1.397-6.743-1.176-9.616-13.498-8.501c-12.321,1.114-20.441-1.016-24.763-2.396c-4.588-1.465-10.448-3.18-15.134-8.447
						c-0.634-0.712-1.613-1.065-2.52-0.77c-2.615,0.851-10.51,6.709-9.951,9.846c0.429,2.411,2.411,4.109,4.109,5.694
						c0.535,0.499,1.983,1.233,2.077,1.972c0.128,1.015-1.273,1.606-2.053,1.719c-2.573,0.373-5.145-0.557-7.528-1.596
						C267.79,443.119,266.891,442.711,265.987,442.355z'
								/>

								<path
									stroke='#303030'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='none'
									d='
						M253.162,436.488c-4.562,1.206-2.211,6.141-1.556,11.798c0.466,4.025-0.417,9.64,3.25,16.485'
								/>

								<path
									stroke='#303030'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='none'
									d='
						M272.134,435.525c-0.041,1.496,0.964,2.807,2.019,3.87c2.042,2.056,4.474,3.724,7.127,4.888c1.86,0.816,4.118,1.707,4.52,3.698
						c0.264,1.309-0.448,2.649-1.431,3.553c-0.982,0.904-2.214,1.479-3.365,2.155c-1.276,0.75-2.472,1.637-3.559,2.641
						c-1.27,1.173-2.449,2.64-2.554,4.366'
								/>

								<path
									stroke='#303030'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='none'
									d='
						M337.971,445.999c-1.397-6.743-1.176-9.616-13.498-8.501c-12.321,1.114-20.441-1.016-24.763-2.396
						c-4.588-1.465-10.448-3.18-15.134-8.447'
								/>

								<path
									stroke='#303030'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='none'
									d='
						M254.855,464.771c8.879,0.38,12.943-1.951,21.61-4.412c8.667-2.461,17.108-1.158,26.055-1.686
						c8.947-0.528,27.124-5.304,35.451-12.674'
								/>
							</g>
						</g>
						<g>
							<path
								fill={theme.numberColor}
								d='M100.29,308.616h84.98c-5.441-0.603,35.398,2.745,60.966,9.157
					c15.734,3.945,26.05,14.81,18.72,39.152c-7.33,24.343-16.767,45.425-16.767,45.425s-23.626,0.7-34.263-9.268
					c0,0,0.278-15.131,4.392-25.237c0,0-45.125,9.019-74.941,3.763c-29.816-5.256-39.497-16.651-43.228-28.025
					c-3.73-11.374-5.126-34.779-5.126-34.779L100.29,308.616z'
							/>
							<path
								stroke='#303030'
								strokeWidth={1.5}
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit={10}
								fill='none'
								d='
					M228.373,350.738c-9.885,11.365-12.547,17.573-14.429,41.762'
							/>
							<path
								fill={theme.dark}
								d='M214.73,382.762c-2.306,0.273-3.033,2.096-2.484,5.523c0.548,3.427,0.279,5.572-0.617,9.116
					c-0.927,3.667,0.274,8.225,10.556,11.789c10.281,3.564,21.865,3.016,24.333,1.097c2.468-1.919,2.236-5.579,4.466-9.116
					c1.156-1.833,4.123-5.712,1.002-7.811l-1.524,3.696c0,0-7.546,2.019-19.65-1.545c-12.105-3.564-16.456-7.836-16.456-7.836
					L214.73,382.762z'
							/>
						</g>
						<g>
							<path
								fill={theme.numberColor}
								d='M89.508,308.616c0,27.614,7.051,53.434,27.634,64.424c18.478,9.866,58.388,8.387,100.679,2.813
					c2.019,11.965,4.871,18.381,12.281,29.177c7.41,10.797,14.091,19.142,14.091,19.142s9.155,0.819,18.226-3.456
					c9.072-4.275,12.592-11.096,12.592-11.096s-14.882-50.309-20.965-66.517c-4.843-12.904-9.457-15.755-34.766-19.411
					c-8.899-1.285-25.528-2.44-35.967-3.742c-16.319-13.341-22.373-11.334-22.373-11.334H89.508z'
							/>
							<g>
								<path
									stroke='#303030'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='none'
									d='
						M160.941,308.616c10.617,2.11,17.405,7.269,20.253,9.695c1.229,1.046,2.722,1.722,4.322,1.928
						c6.969,0.897,28.804,2.685,33.764,3.454c20.287,3.143,29.256,3.944,34.766,19.411c4.7,13.192,9.015,27.236,9.015,27.236'
								/>

								<path
									stroke='#303030'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='none'
									d='
						M216.672,356.651c-0.743,12.898,0.435,21.165,3.766,30.242c3.331,9.078,13.058,23.858,18.923,31.008'
								/>

								<path
									stroke='#303030'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='none'
									d='
						M103.916,308.315c0,16.847,7.745,42.626,22.742,50.171c14.997,7.545,42.348,7.759,89.8,6.627'
								/>

								<path
									stroke='#303030'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='none'
									d='
						M216.507,360.408c5.858,0,7.263,2.66,10.402,16.355c4.121,17.982,17.414,34.076,27.137,44.805'
								/>
							</g>
							<path
								fill={theme.dark}
								d='M237.097,414.857l2.265,3.044c0,0,9.078,0.696,19.593-4.308
					c10.515-5.004,13.585-11.487,13.585-11.487l-1.254-3.777c0,0,3.643-1.72,4.856,2.981c1.213,4.701,0.226,5.914,2.085,8.492
					c1.858,2.578,1.858,5.004-1.781,9.553c-3.639,4.549-18.611,13.355-27.943,12.011c-9.029-1.3-5.83-5.814-7.801-8.003
					C238.73,421.175,234.749,419.76,237.097,414.857z'
							/>
						</g>
					</g>
					<g id='Head'>
						<path
							fill={theme.dark}
							d='M123.975,174.277l-1.278-20.945c6.43-1.015,12.12-1.281,14.078-1.968
				c2.824-0.99,5.225-4.91,5.916-7.714c0.168-0.682,0.864-1.101,1.525-0.861c2.37,0.86,4.582,3.889,4.554,8.699
				c4.39-2.933,8.218-2.596,5.875,2.688c-2.343,5.283-4.165,5.775-4.165,5.775s5.594-1.874,5.447,0.805
				c-0.147,2.679-5.146,4.126-5.146,4.126s2.192,5.577-2.624,7.775L123.975,174.277z'
						/>
						<path
							fill={theme.dark}
							d='M102.691,176.28c0.271,12.549,11.357,26.013,24.496,25.729
				c13.139-0.284,23.356-14.208,23.085-26.757c-0.271-12.549-11.143-22.492-24.282-22.208
				C112.852,153.328,102.42,163.731,102.691,176.28z'
						/>
						<path
							fill={theme.skinColor}
							d='M113.007,197.993c0.166,2.72,1.259,5.206,3.232,7.047l0.22,15.79l-9.044,3.057
				c3.805,4.689,12.936,9.936,25.653,9.16c12.717-0.776,14.971-11.153,14.971-11.153l-7.909-2.989l-0.888-7.813
				c1.545-0.075,7.519-0.563,10.182-3.467c2.663-2.904,2.11-6.611,1.547-12.497c-0.303-3.174-0.632-7.874-0.568-10.781
				c0.077-3.53,0.245-5.63-0.141-10.124c-0.567-6.594-4.43-11.726-4.43-11.726c-2.408,3.816-14.568,6.014-22.854-0.077
				c-5.366,0.327-10.155,4.431-10.947,8.602c1.934,3.397,3.982,7.022,3.984,12.02c0.053,3.447-0.702,3.601-2.091,2.052
				c-1.858-2.004-4.601-3.666-7.414-3.566c-3.663,0.13-5.957,2.366-6.417,5.024c-0.455,2.627,1.732,5.373,4.317,6.332
				c2.586,0.958,5.951,0.829,8.237-0.778C112.648,192.107,112.841,195.273,113.007,197.993z'
						/>
						<g>
							<path
								fill={theme.dark}
								d='M139.243,211.093c-5.865,0.218-12.55-0.639-16.639-2.73c1.236,2.42,5.962,6.079,17.105,6.832
					L139.243,211.093z'
							/>
							<g>
								<g>
									<path
										fill='#FFFFFF'
										d='M139.169,180.873c0.29-1.529,1.639-2.445,3.219-2.77c1.581-0.324,3.916-0.225,4.611,1.955
							C146.684,182.379,141.704,183.678,139.169,180.873z'
									/>
									<path
										fill={theme.dark}
										d='M145.954,181.075c-0.359,1.031-1.528,1.568-2.609,1.199c-1.082-0.369-1.667-1.504-1.308-2.535
							c0.359-1.031,1.528-1.568,2.609-1.199C145.728,178.909,146.313,180.044,145.954,181.075z'
									/>
									<polygon
										fill='#FFFFFF'
										points='141.888,179.701 143.995,180.407 141.743,180.831 						'
									/>

									<path
										stroke='#303030'
										strokeWidth={1.5}
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeMiterlimit={10}
										fill='none'
										d='
							M147.206,179.611c-1.195-1.511-3.07-1.678-4.651-1.354c-1.581,0.324-3.088,1.528-3.385,2.616'
									/>
								</g>

								<path
									stroke='#303030'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='none'
									d='
						M140.498,169.59c3.212-0.358,6.525,0.904,8.252,2.757'
								/>
							</g>
							<g>
								<g>
									<path
										fill='#FFFFFF'
										d='M123.121,182.101c0.29-1.529,1.639-2.445,3.219-2.77c1.581-0.324,3.916-0.225,4.611,1.955
							C130.636,183.607,125.656,184.906,123.121,182.101z'
									/>
									<path
										fill={theme.dark}
										d='M129.906,182.303c-0.359,1.031-1.528,1.568-2.609,1.199c-1.082-0.369-1.667-1.504-1.308-2.535
							c0.359-1.031,1.528-1.568,2.609-1.199C129.68,180.137,130.265,181.271,129.906,182.303z'
									/>
									<polygon
										fill='#FFFFFF'
										points='125.84,180.928 127.947,181.635 125.695,182.058 						'
									/>

									<path
										stroke='#303030'
										strokeWidth={1.5}
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeMiterlimit={10}
										fill='none'
										d='
							M131.158,180.839c-1.195-1.511-3.07-1.678-4.651-1.354c-1.581,0.324-3.088,1.529-3.385,2.616'
									/>
								</g>

								<path
									stroke='#303030'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='none'
									d='
						M119.668,176.153c1.089-2.751,4.435-5.343,7.592-5.772'
								/>
							</g>
							<path
								stroke='#303030'
								strokeWidth={1.5}
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit={10}
								fill='none'
								d='
					M136.115,176.066c0.967,4.556,2.937,7.346,4.142,9.597c0.924,1.726,1.16,4.782-3.25,5.185'
							/>
							<path
								stroke='#303030'
								strokeWidth={1.5}
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit={10}
								fill='none'
								d='
					M103.846,186.396c0.626-0.511,2.223-0.986,3.893-0.668c1.67,0.318,3.123,1.5,3.716,3.023'
							/>
							<path
								stroke='#303030'
								strokeWidth={1.5}
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit={10}
								fill='none'
								d='
					M106.773,187.966c0.462-0.373,2.111-1.358,3.847-0.58'
							/>
							<g>
								<path
									stroke='#303030'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='none'
									d='
						M119.646,182.149c0.47,4.089,4.165,7.023,8.254,6.553c4.089-0.47,7.023-4.165,6.553-8.254s-3.762-3.683-7.851-3.214
						C122.513,177.704,119.176,178.06,119.646,182.149z'
								/>

								<path
									stroke='#303030'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='none'
									d='
						M139.331,179.908c0.278,4.106,3.833,7.21,7.939,6.931c4.106-0.278,7.21-3.833,6.932-7.939
						c-0.278-4.107-3.586-3.855-7.692-3.577C142.403,175.602,139.053,175.801,139.331,179.908z'
								/>

								<path
									stroke='#303030'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='none'
									d='
						M139.593,181.862c-1.5-1.367-3.963-0.919-4.881,0.298'
								/>

								<line
									stroke='#303030'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='none'
									x1='119.52'
									y1='182.143'
									x2='114.252'
									y2='183.691'
								/>
							</g>
							<path
								stroke='#303030'
								strokeWidth={1.5}
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit={10}
								fill='none'
								d='
					M130.298,195.172c3.035,2.039,7.123,2.404,10.472,0.936'
							/>
						</g>
					</g>
					<g id='Arm_00000128478029558011706780000011076961531928074899_'>
						<g>
							<path
								fill={theme.skinColor}
								d='M265.32,240.895c-0.083-0.387-0.24-0.755-0.455-1.087c-0.697-1.08-1.994-1.786-3.261-1.567
					c1.31-0.972,1.697-2.987,0.841-4.375c-0.856-1.388-2.831-1.947-4.287-1.212c0.857-0.75,2.043-3.346,0.06-5.597
					c-1.866-2.119-4.709,0.173-4.797,0.08c0,0,3.16-6.065,3.818-7.286c1.25-2.32,2.064-3.615,2.33-5.3
					c0.307-1.944-1.646-3.471-3.443-2.44c-1.918,1.1-2.997,3.385-4.565,5.774c-1.918,2.925-4.967,8.195-7.355,11.619
					c-1.308,1.875-1.714,2.738-2.181,2.638c-0.987-0.212-0.715-2.79-0.536-3.858c0.272-1.631,0.664-3.297,0.341-4.952
					c-0.231-1.182-1.541-2.604-2.868-2.08c-0.318,0.125-0.569,0.376-0.781,0.645c-1.053,1.337-1.466,3.564-2.023,5.167
					c-0.666,1.918-1.258,3.862-1.774,5.826c-0.712,2.712-1.373,5.39-2.444,8.243c-1.071,2.853-2.997,6.327-4.817,7.619
					c-7.79,5.531-20.43,12.874-24.405,15.156c-4.705-5.209-14.99-16.636-18.834-21.241c-5.052-6.055-8.729-13.048-20.21-16.226
					c-2.767,0.878,2.399,39.825,2.399,39.825s8.191,8.475,15.87,15.502c9.399,8.601,12.328,10.744,18.027,11.765
					c4.278,0.766,9.176-0.985,16.821-7.739c8.643-7.827,21.871-20.346,24.549-22.832c4.361-4.048,9.66-7.449,14.584-11.207
					c2.08-1.587,4.018-3.364,5.987-5.085C263.582,245.208,265.857,243.409,265.32,240.895z'
							/>
							<path
								fill={theme.dark}
								d='M227.813,248.167c0.232,5.092,6.738,13.818,13.528,14.793l1.931-1.703
					c0,0,0.674,1.545-1.173,3.572s-3.491,3.496-5.376,2.926c-2.818-0.852-6.828-2.912-9.769-6.808
					c-2.99-3.96-3.614-6.377-3.894-8.464c-0.278-2.069,0.404-3.232,1.939-4.479c2.084-1.693,3.409-2.058,4.084-1.367
					C229.085,246.638,228.548,247.351,227.813,248.167z'
							/>
							<g>
								<path
									stroke='#303030'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='none'
									d='
						M257.006,233.402c-1.595,1.365-3.202,3.299-5.004,4.376c-1.843,1.102-3.523,1.133-4.606,0.038
						c-1.083-1.094-0.982-2.859-0.044-4.199c1.358-1.94,3.814-4.503,6.069-6.48'
								/>

								<path
									stroke='#303030'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='none'
									d='
						M260.505,239.697c-1.595,1.365-3.121,2.734-4.828,3.956c-1.622,1.161-3.538,1.772-5.076,0.303
						c-1.113-1.064-0.688-2.703,0.25-4.042c1.358-1.94,3.814-4.503,6.069-6.481'
								/>

								<path
									stroke='#303030'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='none'
									d='
						M259.106,249.134c-1.636,1.798-3.296,1.818-4.379,0.724c-1.083-1.094-1.228-2.342-0.29-3.681
						c1.359-1.94,3.814-4.503,6.069-6.48'
								/>
							</g>
							<path
								stroke='#303030'
								strokeWidth={1.5}
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit={10}
								fill='none'
								d='
					M246.877,237.05c0.975,5.472-2.21,9.539-4.931,12.518'
							/>
						</g>
						<g>
							<path
								stroke='#303030'
								strokeWidth={1.5}
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit={10}
								fill='#FFFFFF'
								d='
					M157.051,222.825c13.034,2.645,15.992,4.765,26.425,17.011c1.717,2.015,18.835,21.262,18.835,21.262s6.021-5.598,7.42-2.637
					l-2.019,1.502c-1.351,0.865-1.844,2.639-1.806,4.243c0.062,2.572,1.37,7.283,4.094,11.009
					c4.173,5.708,11.719,7.666,11.719,7.666l2.259-1.489c1.939,2.87-2.301,6.118-14.186,13.226c-3.561,2.13-10.99,4.9-15.745,0.794
					c-1.709-1.476-3.848-3.79-5.758-4.995c-1.208-0.762-2.41-1.134-3.778-1.543c-1.559-0.467-2.567-0.461-4.03-1.174
					c-2.044-0.996-2.502-2.997-3.339-5.111c-0.771-1.948-1.447-3.612-2.834-5.423c-1.373-1.792-12.568-10.277-12.753-10.826
					C161.555,266.341,153.15,245.398,157.051,222.825z'
							/>
							<path
								stroke='#303030'
								strokeWidth={1.5}
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit={10}
								fill='none'
								d='
					M202.311,261.099c-5.048,3.591-8.305,9.913-10.162,16.085'
							/>
							<path
								stroke='#303030'
								strokeWidth={1.5}
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit={10}
								fill='none'
								d='
					M175.68,272.401c1.099,2.948,1.695,6.054,2.483,9.1c0.667,2.582-0.087,5.052,2.319,6.2'
							/>

							<path
								stroke='#303030'
								strokeWidth={1.5}
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit={10}
								fill='#FFFFFF'
								d='
					M221.718,282.882c-4.123-1.349-8.58-3.224-11.719-7.666c-2.635-3.73-3.767-8.452-4.094-11.009
					c-0.21-1.639,0.481-3.256,1.806-4.243c2.778-2.07,7.515-5.587,8.183-5.992c1.89-1.146,2.331,0.656,2.331,0.656l-1.422,1.9
					c0,0-1.632,4.437,2.641,10.884c3.428,5.172,9.054,7.129,9.085,7.109l3.19-2.226c0,0,1.272,1.112-1.284,3.588
					C228.501,277.757,221.718,282.882,221.718,282.882z'
							/>
						</g>
					</g>
					<g id='Chest_00000118372015669686006400000001115330658787068047_'>
						<path
							stroke='#303030'
							strokeWidth={1.5}
							strokeLinecap='round'
							strokeLinejoin='round'
							strokeMiterlimit={10}
							fill='#FFFFFF'
							d='
				M146.059,220.379l14.128,3.144c0,0,11.001,8.566,10.529,28.938c-0.446,19.219,1.699,38.947,7.798,48.152
				c3.77,1.639,6.236,3.807,6.756,8.19c-4.56,5.418-21.897,8.435-34.992,14.889c-21.007,10.355-37.222,23.473-57.819,18.209
				c-9.685-30.769-10.38-61.611-11.474-79.544c-1.094-17.933,6.082-29.428,10.233-34.004l17.682-5.59c0,0,9.382,9.792,27.216,6.389
				C146.073,227.252,146.059,220.379,146.059,220.379z'
						/>

						<path
							stroke='#303030'
							strokeWidth={1.5}
							strokeLinecap='round'
							strokeLinejoin='round'
							strokeMiterlimit={10}
							fill='#FFFFFF'
							d='
				M146.059,220.379l14.128,3.144c0,0,11.001,8.566,10.529,28.938c-0.446,19.219,1.699,38.947,7.798,48.152
				c3.77,1.639,6.236,3.807,6.756,8.19c-4.56,5.418-21.897,8.435-34.992,14.889c-21.007,10.355-37.222,23.473-57.819,18.209
				c-9.685-30.769-10.38-61.611-11.474-79.544c-1.094-17.933,6.082-29.428,10.233-34.004l17.682-5.59c0,0,9.382,9.792,27.216,6.389
				C146.073,227.252,146.059,220.379,146.059,220.379z'
						/>
						<g>
							<path
								fill={theme.accentColor}
								d='M81.709,262.414c0.022,0.366,0.044,0.745,0.066,1.122c31.386-12.011,68.403-20.447,87.772-18.867
					c-0.22-1.564-0.508-3.031-0.851-4.403c-19.254-1.551-55.868,6.758-87.09,18.63C81.607,260.042,81.635,261.21,81.709,262.414z'
							/>
							<path
								fill={theme.accentColor}
								d='M170.008,262.482c-0.084-3.246-0.086-6.532-0.01-9.804c0.023-0.987,0.011-1.937-0.018-2.868
					c-19.191-1.738-56.365,6.681-87.925,18.725c0.219,4.037,0.463,8.52,0.804,13.358
					C113.565,270.007,149.98,261.499,170.008,262.482z'
							/>
							<path
								fill={theme.accentColor}
								d='M170.497,271.831c-0.117-1.441-0.213-2.904-0.293-4.381
					c-20.395-0.672-56.569,7.903-86.941,19.753c0.123,1.492,0.257,3.009,0.403,4.553
					C113.868,279.924,149.924,271.314,170.497,271.831z'
							/>
						</g>

						<path
							stroke='#303030'
							strokeWidth={1.5}
							strokeLinecap='round'
							strokeLinejoin='round'
							strokeMiterlimit={10}
							fill={theme.accentColor}
							d='
				M108.9,222.764c0,0,9.382,9.792,27.216,6.389c9.957-1.9,9.944-8.773,9.944-8.773l-5.289-1.485c0,0-0.348,4.571-8.662,5.255
				c-8.314,0.685-13.855-1.592-16.136-3.77L108.9,222.764z'
						/>
					</g>
					<g id='Laptop'>
						<path
							fill={theme.dark}
							d='M163.328,327.506h33.005v6.022h-35.782c-0.865,0-1.566-0.701-1.566-1.566v-0.113
				C158.986,329.45,160.93,327.506,163.328,327.506z'
						/>
						<g id='Device_00000041977327252372864230000011507634921549395610_'>
							<path
								fill={theme.accentColor}
								d='M196.464,333.526l81.087-2.356c1.983-0.058,3.79-1.701,4.035-3.669l6.765-54.391
					c0.245-1.969-1.164-3.518-3.148-3.461l-81.087,2.356c-1.983,0.058-3.79,1.701-4.035,3.669l-6.765,54.391
					C193.072,332.034,194.481,333.583,196.464,333.526z'
							/>
							<g id='Stickers'>
								<g>
									<path
										fill='#FFFFFF'
										d='M256.244,307.204l-28.114,0.817c-0.859,0.025-1.53-0.738-1.394-1.587l0.955-5.98
							c0.104-0.651,0.656-1.135,1.314-1.155l28.114-0.817c0.859-0.025,1.53,0.738,1.394,1.587l-0.955,5.98
							C257.455,306.7,256.903,307.185,256.244,307.204z'
									/>
									<g>
										<path
											fill={theme.dark}
											d='M230.191,300.672l3.146-0.093c0.366-0.011,0.652,0.312,0.598,0.674l-0.689,4.59
								c-0.042,0.281-0.279,0.491-0.563,0.5l-3.176,0.095c-0.367,0.011-0.655-0.314-0.598-0.678l0.719-4.592
								C229.672,300.888,229.908,300.68,230.191,300.672z'
										/>
										<path
											fill={theme.dark}
											d='M235.572,300.536l1.619-0.036c0.364-0.008,0.648,0.314,0.594,0.674l-0.689,4.591
								c-0.042,0.282-0.282,0.493-0.567,0.5l-1.649,0.038c-0.366,0.009-0.65-0.316-0.594-0.678l0.719-4.593
								C235.049,300.751,235.288,300.542,235.572,300.536z'
										/>
										<path
											fill={theme.dark}
											d='M239.472,300.453l1.231,3.055l1.915-3.148c0.366-0.011,0.652,0.312,0.598,0.674l-0.689,4.59
								c-0.042,0.281-0.279,0.491-0.563,0.5l-3.176,0.095c-0.367,0.011-0.654-0.314-0.598-0.678l0.719-4.592
								C238.953,300.669,239.189,300.461,239.472,300.453z'
										/>
										<path
											fill={theme.dark}
											d='M244.853,300.316l1.619-0.036c0.364-0.008,0.648,0.314,0.594,0.674l-0.689,4.591
								c-0.042,0.282-0.282,0.493-0.567,0.5l-1.649,0.038c-0.366,0.008-0.65-0.316-0.594-0.678l0.719-4.593
								C244.33,300.532,244.568,300.323,244.853,300.316z'
										/>
										<path
											fill={theme.dark}
											d='M248.753,300.233l3.146-0.093c0.366-0.011,0.652,0.312,0.598,0.674l-0.689,4.59
								c-0.042,0.281-0.279,0.491-0.563,0.5l-3.176,0.095c-0.368,0.011-0.654-0.314-0.598-0.678l0.719-4.592
								C248.234,300.45,248.47,300.242,248.753,300.233z'
										/>
										<path
											fill='#FFFFFF'
											d='M249.433,301.801l1.408-0.042c0.164-0.005,0.292,0.14,0.268,0.302l-0.308,2.054
								c-0.019,0.126-0.125,0.22-0.252,0.224l-1.421,0.043c-0.164,0.005-0.293-0.141-0.267-0.303l0.322-2.055
								C249.201,301.898,249.306,301.804,249.433,301.801z'
										/>
										<path
											fill={theme.dark}
											d='M254.134,300.098l1.619-0.036c0.364-0.008,0.648,0.314,0.594,0.674l-0.689,4.591
								c-0.042,0.282-0.282,0.493-0.567,0.5l-1.649,0.038c-0.366,0.008-0.65-0.316-0.594-0.678l0.719-4.593
								C253.611,300.313,253.849,300.104,254.134,300.098z'
										/>
									</g>
								</g>
							</g>
						</g>
					</g>
					<g id='Arm_00000162318998202074277350000013732431225619341457_'>
						<g>
							<path
								fill={theme.skinColor}
								d='M141.489,313.754c1.481,0.094,2.985-0.088,4.426-0.458c1.229-0.316,2.419-0.766,3.573-1.29
					c1.035-0.47,2.008-1.096,3.098-1.419c0.563-0.167,1.154-0.212,1.74-0.237c1.193-0.05,2.389-0.019,3.577,0.094
					c1.765,0.167,3.696,0.561,5.462,0.261c0.717-0.122,1.426-0.464,1.835-1.065c0.439-0.646,0.653-1.531,0.371-2.287
					c-0.428-1.145-1.437-2.221-2.516-2.768c-0.849-0.43-1.786-0.652-2.72-0.831c-1.027-0.198-2.063-0.349-3.098-0.5
					c-1.213-0.177-2.426-0.355-3.64-0.532c-2.227-0.326-4.471-0.652-6.72-0.553c-1.072,0.047-2.14,0.192-3.187,0.431
					c-1.458,0.333-2.893,0.86-4.102,1.74c-1.209,0.88-2.181,2.14-2.522,3.596c-0.419,1.787,0.356,4.987,2.409,5.508
					C140.136,313.61,140.81,313.711,141.489,313.754z'
							/>
							<path
								fill={theme.dark}
								opacity={0.2}
								d='M141.489,313.754c1.481,0.094,2.985-0.088,4.426-0.458
					c1.229-0.316,2.419-0.766,3.573-1.29c1.035-0.47,2.008-1.096,3.098-1.419c0.563-0.167,1.154-0.212,1.74-0.237
					c1.193-0.05,2.389-0.019,3.577,0.094c1.765,0.167,3.696,0.561,5.462,0.261c0.717-0.122,1.426-0.464,1.835-1.065
					c0.439-0.646,0.653-1.531,0.371-2.287c-0.428-1.145-1.437-2.221-2.516-2.768c-0.849-0.43-1.786-0.652-2.72-0.831
					c-1.027-0.198-2.063-0.349-3.098-0.5c-1.213-0.177-2.426-0.355-3.64-0.532c-2.227-0.326-4.471-0.652-6.72-0.553
					c-1.072,0.047-2.14,0.192-3.187,0.431c-1.458,0.333-2.893,0.86-4.102,1.74c-1.209,0.88-2.181,2.14-2.522,3.596
					c-0.419,1.787,0.356,4.987,2.409,5.508C140.136,313.61,140.81,313.711,141.489,313.754z'
							/>
							<path
								fill={theme.skinColor}
								d='M173.018,304.731c0.357-1.45,0.021-3.004-0.653-4.337c-0.673-1.333-1.778-3.308-2.792-4.404
					c-2.782-3.009-4.108-3.588-5.529-4.583c-1.837-1.286-10.872-4.091-14.037-3.447c-2.787,0.567-11.593,4.509-15.574,6.013
					c-6.926,2.617-42.22,3.459-42.22,3.459l5.44-32.107c0,0,0.496-20.392-6.558-34.167c-15.184,4.584-16.871,11.356-21.257,20.945
					c-4.385,9.589-9.104,49.281-9.11,58.148c-0.009,12.696,6.116,18.24,23.669,15.166c17.514-3.068,35.3-9.902,42.596-11.505
					c5.759-1.266,6.848,0.737,11.029,0.408c2.346-0.185,4.009-0.856,5.945-2.139c1.111-0.736,2.329-2.375,3.535-2.975
					c2.064-1.026,3.373-0.69,4.562,0.124c1.451,0.993,2.668,2.331,4.029,4.424c0.382,0.587,0.806,1.186,1.697,1.522
					c0.655,0.248,1.635,0.167,2.166-0.289c0.474-0.407,0.561-1.466,0.495-1.809c-0.742-3.887-1.96-5.2-3.462-6.92
					c-0.586-0.672-1.719-1.817-2.964-2.824c2.297,0.574,4.038,1.886,5.839,3.334c1.732,1.392,2.648,3.543,4.304,5.015
					c0.842,0.748,2.043,1.322,3.075,0.871c0.771-0.336,1.703-1.694,1.529-2.517c0.369,0.403,1.124,0.74,1.943,0.548
					c1.981-0.464,2.164-3.419,1.322-5.091C172.499,305.776,172.9,305.212,173.018,304.731z'
							/>
							<g>
								<path
									stroke='#303030'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='none'
									d='
						M157.003,297.306c3.616,1.759,6.175,3.252,8.657,6.415c1.063,1.355,2.342,3.081,3.113,6.415'
								/>

								<path
									stroke='#303030'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='none'
									d='
						M160.941,293.519c5.732,2.764,8.952,6.941,11.464,11.968'
								/>
							</g>
						</g>
						<g>
							<path
								stroke='#303030'
								strokeWidth={1.5}
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit={10}
								fill='#FFFFFF'
								d='
					M91.217,228.354c-16.085,5.248-19.766,10.585-23.746,25.236c-3.979,14.651-5.246,43.834-8.14,47.24
					c-2.894,3.406-5.544,8.312-0.118,13.41c0.362,8.534,2.532,19.63,11.954,19.713c9.678,0.085,19.887-2.161,23.493-4.271
					c3.19-1.867,1.849-5.017,1.849-5.017l0,0c-1.954,0.3-3.857-0.79-4.57-2.634c-1.148-2.97-2.404-7.823-1.482-13.483
					c1.355-8.313,7.699-12.695,7.699-12.695c0.404-1.878-1.307-2.333-4.742-1.878l4.742-26.927
					C98.157,267.047,101.349,240.134,91.217,228.354z'
							/>

							<path
								stroke='#303030'
								strokeWidth={1.5}
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit={10}
								fill='#FFFFFF'
								d='
					M96.407,324.679c-1.882,0.271-3.734-0.745-4.47-2.498c-1.299-3.097-2.795-8.25-1.478-13.634c2-8.182,7.699-12.695,7.699-12.695
					c9.2-1.349,14.961-2.826,14.961,0.695l-2.692,0.325c-2.418,2.463-6.6,7.746-6.86,15.034c-0.14,3.929,1.811,8.661,1.811,8.661
					l2.668-1.003C109.54,323.18,103.834,323.61,96.407,324.679z'
							/>
							<path
								stroke='#303030'
								strokeWidth={1.5}
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit={10}
								fill='none'
								d='
					M68.222,287.748c-0.78,3.205-2.234,6.929-4.502,9.325c-2.204,2.328-4.204,3.698-6.082,6.295'
							/>
							<path
								stroke='#303030'
								strokeWidth={1.5}
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeMiterlimit={10}
								fill='none'
								d='
					M93.415,293.974c-5.969,1.348-8.83,3.123-12.454,6.002c-3.623,2.879-6.405,6.701-8.614,10.768'
							/>
						</g>
						<g>
							<path
								fill={theme.dark}
								d='M129.511,294.962c-2.998,0.308-5.474,5.921-5.711,9.369c-0.238,3.449,1.514,8.458,3.195,9.581
					l1.682-0.297c0,0-0.076,0.763-1.273,1.22c-1.336,0.511-4.486,1.748-5.971,1.451c-1.646-0.329-5.377-5.542-4.75-12.041
					c0.69-7.158,4.682-9.307,5.752-9.665c1.07-0.358,6.761-0.815,7.721-0.824c1.219-0.011,1.219,0.943,1.219,0.943L129.511,294.962z
					'
							/>
							<g>
								<path
									stroke='#303030'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill={theme.dark}
									d='
						M118.14,298.705l7.497-0.887c1.029-0.122,1.892,0.769,1.738,1.793l-1.113,7.408c-0.102,0.677-0.63,1.21-1.305,1.318
						l-7.464,1.194c-1.043,0.167-1.95-0.723-1.804-1.769l1.08-7.715C116.868,299.34,117.432,298.789,118.14,298.705z'
								/>
								<path
									stroke='#303030'
									strokeWidth={1.5}
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeMiterlimit={10}
									fill='#FFFFFF'
									d='
						M116.772,297.77l7.497-0.887c1.029-0.122,1.892,0.769,1.738,1.793l-1.113,7.408c-0.102,0.677-0.63,1.21-1.305,1.318
						l-7.464,1.194c-1.043,0.167-1.95-0.723-1.804-1.769l1.08-7.715C115.5,298.405,116.063,297.854,116.772,297.77z'
								/>
							</g>
						</g>
					</g>
				</g>
			</g>
			<g id='Speech_Bubble'>
				<g id='Speech_Bubble_00000065057721466652610310000016199397660598886019_'>
					<path
						id='Speech_Bubble_00000015333834963227924810000017942497463486087356_'
						stroke='#303030'
						strokeWidth={1.5}
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeMiterlimit={10}
						fill='#FFFFFF'
						d='
			M83.67,178.209c-2.687-1.838-4.927-4.244-6.405-6.765c7.456-5.011,12.254-12.804,12.254-21.564
			c0-15.118-14.273-27.374-31.88-27.374c-17.607,0-31.88,12.256-31.88,27.374c0,15.118,14.273,27.374,31.88,27.374
			c3.063,0,6.02-0.378,8.823-1.07c4.31,3.465,10.177,5.195,16.382,4.811C84.295,180.906,84.87,179.03,83.67,178.209z'
					/>
					<path
						id='Gear'
						fill={theme.numberColor}
						d='M74.128,146.548l-3.58-0.3c-0.29-1.03-0.69-2.02-1.21-2.93l2.32-2.74
			c0.35-0.42,0.33-1.04-0.06-1.43l-3.23-3.22c-0.39-0.39-1.01-0.42-1.43-0.06l-2.73,2.31c-0.92-0.52-1.9-0.92-2.94-1.21l-0.29-3.57
			c-0.05-0.55-0.51-0.97-1.06-0.97h-4.56c-0.55,0-1.01,0.42-1.05,0.97l-0.3,3.57c-1.04,0.29-2.02,0.69-2.94,1.21l-2.73-2.31
			c-0.42-0.36-1.04-0.33-1.43,0.06l-3.23,3.22c-0.38,0.39-0.41,1.01-0.06,1.43l2.32,2.74c-0.52,0.91-0.92,1.9-1.21,2.93l-3.58,0.3
			c-0.54,0.04-0.96,0.5-0.96,1.05v4.57c0,0.54,0.42,1,0.96,1.05l3.58,0.3c0.29,1.03,0.69,2.01,1.21,2.93l-2.32,2.73
			c-0.35,0.42-0.32,1.04,0.06,1.43l3.23,3.23c0.39,0.39,1.01,0.41,1.43,0.06l2.74-2.31c0.91,0.51,1.89,0.92,2.93,1.21l0.3,3.57
			c0.04,0.55,0.5,0.97,1.05,0.97h4.56c0.55,0,1.01-0.42,1.06-0.97l0.29-3.57c1.04-0.29,2.02-0.7,2.94-1.21l2.73,2.31
			c0.42,0.35,1.04,0.33,1.43-0.06l3.23-3.23c0.39-0.39,0.41-1.01,0.06-1.43l-2.32-2.73c0.52-0.92,0.92-1.9,1.21-2.93l3.58-0.3
			c0.55-0.05,0.97-0.51,0.97-1.05v-4.57C75.098,147.048,74.678,146.588,74.128,146.548z M57.638,157.968
			c-4.47,0-8.09-3.62-8.09-8.09c0-4.46,3.62-8.09,8.09-8.09s8.09,3.63,8.09,8.09C65.728,154.348,62.107,157.968,57.638,157.968z'
					/>
				</g>
			</g>
		</svg>
	);
};

export default FrontEndImg;
