import React from "react";

const OpoleUniversityLogoImg = () => (
	<svg width='100%' height='100%' viewBox='0 0 605 255'>
		<path
			fill='#12110c'
			d='M-521.78,136.09c0,9.28-8.23,16.94-18.15,16.94-10.22,0-18.25-7.56-18.25-17.14,0-9,8.39-16.75,18.25-16.75s18.15,7.66,18.15,17m-32.47-.2c0,7.66,6.3,13.7,14.27,13.7s14.27-6.09,14.27-13.45c0-7.52-6.35-13.56-14.27-13.56s-14.27,6-14.27,13.31'
			transform='translate(715.45 -31.53)'
		></path>
		<path
			fill='#12110c'
			d='M-510.66,152.44h-3.94V119.68h6.35c4.3,0,6.76.64,8.81,2.31a8.8,8.8,0,0,1,3.25,6.93c0,5.69-4.25,9.18-11.07,9.18h-3.4Zm3.14-17.68c4.83,0,7.5-2.16,7.5-5.94s-2.62-5.75-7.76-5.75h-2.88v11.69Z'
			transform='translate(715.45 -31.53)'
		></path>
		<path
			fill='#12110c'
			d='M-456.17,136.09c0,9.28-8.23,16.94-18.15,16.94-10.23,0-18.25-7.56-18.25-17.14,0-9,8.39-16.75,18.25-16.75s18.15,7.66,18.15,17m-32.47-.2c0,7.66,6.29,13.7,14.26,13.7s14.28-6.09,14.28-13.45c0-7.52-6.35-13.56-14.28-13.56s-14.26,6-14.26,13.31'
			transform='translate(715.45 -31.53)'
		></path>
		<polygon
			fill='#12110c'
			points='270.39 117.53 279.99 117.53 279.99 120.92 266.46 120.92 266.46 88.16 270.39 88.16 270.39 117.53'
		></polygon>
		<polygon
			fill='#12110c'
			points='289.17 91.54 289.17 101.32 302.96 101.32 302.96 104.7 289.17 104.7 289.17 117.53 303.33 117.53 303.33 120.91 285.23 120.91 285.23 88.15 303.33 88.15 303.33 91.54 289.17 91.54'
		></polygon>
		<path
			fill='#12110c'
			d='M-387.89,139.77c0,3.39.26,4.86,1.15,6.28a9.13,9.13,0,0,0,7.4,3.54,9.07,9.07,0,0,0,7.34-3.54c.89-1.42,1.16-2.89,1.16-6.28V119.68h3.94v21.07c0,3.54-.64,5.75-2.32,7.76A13.22,13.22,0,0,1-379.34,153a13.22,13.22,0,0,1-10.18-4.52c-1.68-2-2.3-4.17-2.3-7.76V119.68h3.93Z'
			transform='translate(715.45 -31.53)'
		></path>
		<polygon
			fill='#12110c'
			points='357.65 86.78 384.88 113.54 384.88 88.15 388.82 88.15 388.82 122.39 361.59 95.67 361.59 120.92 357.65 120.92 357.65 86.78'
		></polygon>
		<rect
			fill='#12110c'
			x='397.88'
			y='88.15'
			width='3.93'
			height='32.76'
		></rect>
		<polygon
			fill='#12110c'
			points='420.91 113.1 430.83 88.16 435.08 88.16 420.86 122.83 406.7 88.16 410.99 88.16 420.91 113.1'
		></polygon>
		<polygon
			fill='#12110c'
			points='443.88 91.54 443.88 101.32 457.67 101.32 457.67 104.7 443.88 104.7 443.88 117.53 458.04 117.53 458.04 120.91 439.94 120.91 439.94 88.15 458.04 88.15 458.04 91.54 443.88 91.54'
		></polygon>
		<path
			fill='#12110c'
			d='M-230,152.44h-4.78l-10.54-14.34h-1v14.34h-3.94V119.68h5.68c4,0,6.39.49,8.38,1.77a8.72,8.72,0,0,1,3.89,7.27c0,5.11-3.41,8.59-8.81,9.09Zm-13.85-17.58c5,0,7.71-2.11,7.71-6.14,0-3.69-2.73-5.65-7.87-5.65h-2.31v11.79Z'
			transform='translate(715.45 -31.53)'
		></path>
		<path
			fill='#12110c'
			d='M-208.31,126.12c-1.79-2.65-3.31-3.54-6-3.54-3.26,0-5.77,2.17-5.77,5.06a4.11,4.11,0,0,0,2.21,3.54,19.19,19.19,0,0,0,2.72,1.37l1.1.49,1.1.44,1.11.45c5.6,2.31,7.92,4.91,7.92,9,0,5.65-4.83,10.12-11,10.12-5.61,0-9.5-3.05-10.85-8.45l3.77-1c.94,3.93,3.36,6,7,6,4,0,7.13-2.79,7.13-6.43a5.1,5.1,0,0,0-2.89-4.66,18.32,18.32,0,0,0-3-1.48l-1-.49-1-.44-1.05-.45c-5-2.11-7.08-4.42-7.08-8.1,0-4.76,4.24-8.4,9.8-8.4a10,10,0,0,1,9,5.11Z'
			transform='translate(715.45 -31.53)'
		></path>
		<rect
			fill='#12110c'
			x='518.66'
			y='88.15'
			width='3.93'
			height='32.76'
		></rect>
		<polygon
			fill='#12110c'
			points='540.17 120.91 536.23 120.91 536.23 91.54 527.79 91.54 527.79 88.15 548.67 88.15 548.67 91.54 540.17 91.54 540.17 120.91'
		></polygon>
		<polygon
			fill='#12110c'
			points='551.86 88.15 556.38 88.15 565.29 102.79 574.31 88.15 578.87 88.15 567.24 106.82 567.24 120.91 563.29 120.91 563.29 106.82 551.86 88.15'
		></polygon>
		<path
			fill='#12110c'
			d='M-521.78,182.34c0,9.28-8.23,16.95-18.15,16.95-10.22,0-18.25-7.57-18.25-17.15,0-9,8.39-16.74,18.25-16.74s18.15,7.66,18.15,16.94m-32.47-.2c0,7.66,6.3,13.7,14.27,13.7s14.27-6.09,14.27-13.45c0-7.52-6.35-13.55-14.27-13.55s-14.27,6-14.27,13.3'
			transform='translate(715.45 -31.53)'
		></path>
		<polygon
			fill='#12110c'
			points='204.78 137.79 204.78 147.57 216.37 147.57 216.37 150.96 204.78 150.96 204.78 167.16 200.85 167.16 200.85 134.41 216.74 134.41 216.74 137.79 204.78 137.79'
		></polygon>
		<polygon
			fill='#12110c'
			points='248.94 167.16 239.87 167.16 239.87 141.63 232.47 141.63 232.47 134.41 256.5 134.41 256.5 141.63 248.94 141.63 248.94 167.16'
		></polygon>
		<polygon
			fill='#12110c'
			points='269.55 141.63 269.55 147.08 279.78 147.08 279.78 154.3 269.55 154.3 269.55 159.95 280.36 159.95 280.36 167.17 260.48 167.17 260.48 134.41 280.36 134.41 280.36 141.63 269.55 141.63'
		></polygon>
		<path
			fill='#12110c'
			d='M-404.14,177.08A10.08,10.08,0,0,0-412,173.5c-5.45,0-9.39,3.78-9.39,9,0,5.05,4,8.78,9.39,8.78a10.64,10.64,0,0,0,7.88-3.43V198c-4.26,1.33-5.67,1.62-8,1.62-10.54,0-18.73-7.47-18.73-17.1s8.24-17.23,18.52-17.23a21.74,21.74,0,0,1,8.24,1.67Z'
			transform='translate(715.45 -31.53)'
		></path>
		<polygon
			fill='#12110c'
			points='339.31 147.08 339.31 134.41 348.38 134.41 348.38 167.17 339.31 167.17 339.31 153.71 326.14 153.71 326.14 167.17 317.07 167.17 317.07 134.41 326.14 134.41 326.14 147.08 339.31 147.08'
		></polygon>
		<polygon
			fill='#12110c'
			points='355.72 134.41 364.8 134.41 381.54 154.44 381.54 134.41 390.6 134.41 390.6 167.17 381.54 167.17 364.8 147.13 364.8 167.17 355.72 167.17 355.72 134.41'
		></polygon>
		<path
			fill='#12110c'
			d='M-280.37,182.39c0,10-8.29,17.48-19.36,17.48s-19.35-7.46-19.35-17.77c0-9.63,8.5-17.15,19.35-17.15,11.07,0,19.36,7.46,19.36,17.44m-29.22,0c0,5.11,4.41,9.23,9.86,9.23s9.86-4.12,9.86-9.23-4.41-9.19-9.86-9.19-9.86,4.13-9.86,9.19'
			transform='translate(715.45 -31.53)'
		></path>
		<polygon
			fill='#12110c'
			points='449.91 159.95 460.82 159.95 460.82 167.16 440.83 167.16 440.83 134.41 449.91 134.41 449.91 159.95'
		></polygon>
		<path
			fill='#12110c'
			d='M-213.5,182.39c0,10-8.29,17.48-19.36,17.48s-19.36-7.46-19.36-17.77c0-9.63,8.5-17.15,19.36-17.15,11.07,0,19.36,7.46,19.36,17.44m-29.22,0c0,5.11,4.41,9.23,9.86,9.23s9.87-4.12,9.87-9.23-4.41-9.19-9.87-9.19-9.86,4.13-9.86,9.19'
			transform='translate(715.45 -31.53)'
		></path>
		<path
			fill='#12110c'
			d='M-172.69,180.52c-.11,5.06-.64,7.76-2.05,10.51-3,5.75-8.56,8.84-15.9,8.84-11.07,0-18.67-7.07-18.67-17.38,0-10.46,7.71-17.59,19-17.59,7.44,0,12.43,2.95,15.73,9.23l-8.65,3.35c-1.58-3.54-4-5.21-7.5-5.21-5.2,0-9.08,4.37-9.08,10.27s3.88,10.31,9.34,10.31c4.14,0,6.61-1.91,7.18-5.69h-7.44v-6.64Z'
			transform='translate(715.45 -31.53)'
		></path>
		<polygon
			fill='#12110c'
			points='541.91 134.41 552.76 134.41 559.37 143.35 565.72 134.41 576.73 134.41 563.62 151.45 563.62 167.17 554.55 167.17 554.55 151.45 541.91 134.41'
		></polygon>
		<path
			fill='#0059a0'
			d='M-638,177.57a26.7,26.7,0,0,1-7.49-23.41,35.63,35.63,0,0,0,.62-6.67c0-10.29-4.33-18.93-11.88-23.71-6.73-4.31-14.54-4.31-22.8-4.31h-9.67v13.05h9.67c7.6,0,12.46.16,15.74,2.27,3.71,2.34,5.84,7,5.84,12.7v0a19.14,19.14,0,0,1-1.42,7.55,10.3,10.3,0,0,1-4.15,4.87c-2.76,1.62-6.15,1.92-11.85,1.92H-689.2v37.21h13.11V174.9h.72c6.44,0,12.25-.29,17.93-3.43a40.31,40.31,0,0,0,9.78,15.75A40.57,40.57,0,0,0-617.52,199V185.37a26.85,26.85,0,0,1-20.46-7.8'
			transform='translate(715.45 -31.53)'
		></path>
		<path
			fill='#0059a0'
			d='M-579.1,162.08a40.18,40.18,0,0,0-11.76-30.59,40.49,40.49,0,0,0-26.66-11.79v13.69a26.79,26.79,0,0,1,17,7.75,26.63,26.63,0,0,1,7.75,20.94Z'
			transform='translate(715.45 -31.53)'
		></path>
	</svg>
);

export default OpoleUniversityLogoImg;
